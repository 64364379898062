import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import './index.scss';
import { App } from '@cartier-studio/containers';
import { FullPageLoader } from '@cartier-studio/components';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

// eslint-disable-next-line no-unused-vars
import i18n from './locales/i18n';

import { store, persistor } from '@cartier-studio/redux';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://4c9fc32ffe784d339490c43fd70e4aa0@o1159998.ingest.sentry.io/4505115150843904",
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        environment: process.env.NODE_ENV,
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={ store }>
            <PersistGate loading={ <FullPageLoader /> } persistor={ persistor }>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
