import React from 'react';
import Project from './Project';
import paper from 'paper';

/**
 * Class representing a generic roman number
 */
class Roman {
    constructor(positionIndex: int) {
        this.positionIndex = positionIndex;
        this.segments      = [];
        this.debugPaths    = [];

        this.boundingBoxCorners = [ {x: 0, y: 0}, {x: 0, y: 0} ];
        this.buildSegments();
    }

    getCompoundPath() {
        return new paper.CompoundPath({
           children: this.segments.map(poly => {
                const child = new paper.Path();
                poly.forEach((segment, i) => {
                    child.join(segment.path);
                    child.join(new paper.Path(
                        segment.lineTo(poly[i < (poly.length - 1) ? i + 1 :0])
                    ));
                });
                return child;
            })
        });
    }

    getSplittedPath() {
        return this.segments.map((segments, j) => {
            return segments.map((segment, i) => (
                <g key={ i }>
                    <path strokeDasharray="1" className="debug-path" d={ segment.radius?.pathData } />
                    <path className="numeral-path" d={ segment.path.pathData } />
                    {(i < (segments.length - 1)) && (
                        <path className="numeral-path" d={ segment.lineTo(segments[i + 1]) } />
                    )}
                    {(i === (segments.length - 1)) && (
                        <path className="numeral-path" d={ segment.lineTo(segments[0]) } />
                    )}

                    {['start', 'end'].map(key => (
                        <g key={ key } className="debug-marks">
                            <circle cx={segment[key]?.x} cy={segment[key]?.y} fill="#003bff22" stroke="#003bff" stroke-width={0.01} r={0.1}/>
                            { segment.data && (
                            <text stroke='#FF0000' strokeWidth='0.05' fontSize='0.03em' x={segment[key]?.x} y={segment[key]?.y}>
                                { segment.data[`${key}Label`] }
                            </text>
                            )}
                        </g>
                    ))}

                </g>
            ));
        });
    }


    getDebugMarkers() {
        return this.segments.map((poly, j) => {
            return poly.map((segment, i) => {
                return ['start', 'end'].map(key => (
                    <g key={ key } className="debug-marks">
                        <circle cx={segment[key]?.x} cy={segment[key]?.y} fill="#003bff22" stroke="#003bff" stroke-width={0.01} r={0.1}/>
                        { segment.data && (
                        <text stroke='#FF0000' strokeWidth='0.05' fontSize='0.03em' x={segment[key]?.x} y={segment[key]?.y}>
                            { segment.data[`${key}Label`] }
                        </text>
                        )}
                    </g>
                ));
            });
        });
    }

    toJsx(joinPaths) {
        const project = Project.getInstance();
        if (!project)
            return null;

        const { offset, followOffsetAxis } = project.numerals[this.positionIndex];
        const radialOffset = !!followOffsetAxis ? offset : 0;

        return (
            <g id={`roman${this.positionIndex}`} transform={`rotate(${(this.positionIndex * 30)  + radialOffset} ${project.opening.center.x} ${project.opening.center.y})`}>
                <path className="numeral-path" d={ this.getCompoundPath().pathData } />
                { this.getDebugMarkers() }
                { this.getJsxExtraPaths() }
            </g>
        );
    }

    toSvg() {
        const project = Project.getInstance();
        if (!project)
            return null;

        const offset  = project.numerals[this.positionIndex].offset;

        const svg = [];
        svg.push(`<g id="roman${this.positionIndex}" transform="rotate(${(this.positionIndex * 30) + offset } ${project.opening.center.x} ${project.opening.center.y})">`);
        svg.push(`<path class="numeral-path" d="${this.getCompoundPath().pathData}" />`);
        svg.push(this.getSvgExtraPaths());
        svg.push('</g>');

        return svg.join('');
    }

    getJsxBoundingBox() {
        const corners = this.boundingBoxCorners;
        const project = Project.getInstance();
        const offset  = project.numerals[this.positionIndex].offset;

        const points = corners.map(corner => [corner.x, corner.y].join(','));
        return (
            <g transform={`rotate(${(this.positionIndex * 30) + offset } ${project.opening.center.x} ${project.opening.center.y})`}>
                <polygon
                    className="numeral-bounding-box"
                    points={ points.join(' ') }
                />
            </g>
        );
    }

    buildSegments() {
        this.segments = [];
        this.debugPaths = [];
    }

    getJsxExtraPaths() {
        return null;
    }

    getSvgExtraPaths() {
        return '';
    }
}

export default Roman;
