import RestClient from './RestClient';
import { User } from '@cartier-studio/models';

class Users extends RestClient {
    constructor() {
        super({
            // rest config
            model: User,
            entryPoint: 'users',
            sortBy: 'lastname',
            // redux config
            resource: 'user',
            resources: 'users',
            id_field: '_id'
        });
    }

    create(payload) {
        payload = new this.model(payload);

        return this.POST('/users/register', { user: payload })
            .then(response => response.json())
            .then(json => {
                if (json.user) {
                    json.user = new this.model(json.user);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const UsersApi = new Users();

export default UsersApi;
