import './style.scss';

const RadialLines = ({ project }) => {
    if (!project)
        return null;

    const { layers, numerals } = project;
    const { center }           = project.opening;
    const { xOffset, yOffset } = project.numeralArea.center;

    return (
        <g className="RadialLines">
            { [...Array(12)].map((_,i) => (
                <g key={ i }>
                    <line
                        className="radial-line"
                        x1={ center.x + xOffset }
                        y1={ center.y + yOffset }
                        x2={ center.x + xOffset }
                        y2={ 0 }
                        stroke="#000000"
                        stroke-width="0.01"
                        strokeDasharray="0.5"
                        transform={ `rotate(${30 * (i + 1) } ${center.x + xOffset} ${center.y + yOffset})` }
                    />
                    { layers.active && (layers.active === i + 1) && (
                        <line
                            className="radial-line active"
                            x1={ center.x + xOffset }
                            y1={ center.y + yOffset }
                            x2={ center.x + xOffset }
                            y2={ 0 }
                            strokeDasharray="0.5"
                            transform={ `rotate(${30 * (i + 1) + numerals[i + 1].offset } ${center.x + xOffset} ${center.y + yOffset})` }
                        />
                    )}
                </g>
            ))}
        </g>
    );
};

export default RadialLines;
