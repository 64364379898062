import { combineReducers } from 'redux';
import auth from './auth';
import editor from './editor';
import users from './users';
import directories from './directories';
import projects from './projects';

export default combineReducers({
    auth,
    editor,
    users,
    projects,
    directories,
});
