import Roman from './Roman';
import Project from './Project';
import Segment from './Segment';

/**
 * Class representing a roman 1 number
 *
 *           L_____________A
 *           |             |
 *           K____J   C____B
 *                |   |
 *                |   |
 *                |   |
 *                |   |
 *                |   |
 *                |   |
 *           H____I   D____E
 *           |             |
 *           G_____________F
 */
class RomanOne extends Roman {
    constructor() {
        super(/*index*/1);
    }

    buildSegments() {
        const project = Project.getInstance();
        if (!project.numerals)
            return;

        const serifWidth = project.numerals.common.serifWidth;
        const barsWidth  = project.numerals.common.barsWidth;

        const radialOffset = project.numerals[this.positionIndex].followOffsetAxis ? 0 : project.numerals[this.positionIndex].offset / 2.6;

        // shortcut
        const s = (marker, first, last, data) => Segment.onOpening(1, marker, first, last, /*xOffset*/0, /*yOffset*/0, /*radialOffset*/0, data);

        const A = (barsWidth + 2 * serifWidth) / 2 + radialOffset;
        const L = -1 * A + (2 * radialOffset);
        const B = A;
        const C = B - serifWidth;
        const D = C;
        const E = D + serifWidth;
        const F = E;
        const G = L;
        const H = G;
        const I = H + serifWidth;
        const J = I;
        const K = L;

        const LA = s(4, L, A, {startLabel: 'L', endLabel: 'A'});
        const BC = s(3, B, C, {startLabel: 'B', endLabel: 'C'});
        const DE = s(2, D, E, {startLabel: 'D', endLabel: 'E'});
        const FG = s(1, F, G, {startLabel: 'F', endLabel: 'G'});
        const HI = s(2, H, I, {startLabel: 'H', endLabel: 'I'});
        const JK = s(3, J, K, {startLabel: 'J', endLabel: 'K'});

        this.segments = [
            [ LA, BC, DE, FG, HI, JK ]
        ];

        this.boundingBoxCorners = [ LA.start, LA.end, FG.start, FG.end ];
    }
}

export default RomanOne;
