import Roman from './Roman';
import Project from './Project';
import Segment from './Segment';

/**
 * Class representing a roman 9 number
 *
 * <-----   U_______________________V  f____________A
 *          |                       |  |            |
 * <-----   e______d   T______S  X__W  a_____Z C____B
 *                 |   |       \  \          //
 *                 |   |        \  \        //
 *                 |   |         \  \      //
 *                 |   |          \  \    //
 *                 |   |           \  \  //
 *                 |   |            \   Y/
 *                 |   |             R  D
 *                 |   |             /  \
 *                 |   |            / K  \
 *                 |   |           //  \  \
 *                 |   |          //    \  \
 *                 |   |         //      \  \
 * ----->   b______c   P_______Q/L__M  I__J  \E___F
 *          |                       |  |          |
 * ----->   O_______________________N  H__________G
 *
 */
class RomanNine extends Roman {
    constructor() {
        super(/*index*/9);
    }

    /**
     * Compensate markers orientation
     */
    fix(v) {
        return 1 * v;
    }

    buildSegments() {
        const project = Project.getInstance();
        if (!project.numerals)
            return;

        const common   = project.numerals.common;
        const numerals = project.numerals[this.positionIndex];

        const sfW  = common.serifWidth;
        const bW   = common.barsWidth;
        const tbW  = common.thinBarsWidth;
        const ftw  = numerals.fullTopWidth;
        const fbw  = numerals.fullBottomWidth + 0.0001;
        const xftw = numerals.xFullTopWidth;
        const xfbw = numerals.xFullBottomWidth;
        const radialOffset = project.numerals[this.positionIndex].followOffsetAxis ? 0 : project.numerals[this.positionIndex].offset / 2.6;

        // shortcut
        const s = (marker, first, last, data) => Segment.onOpening(9, marker, first, last, /*xOffset*/0, /*yOffset*/0, /*radialOffset*/0, data);

        const A = ftw / 2 + sfW + radialOffset;
        const U = -1 * A + (2 * radialOffset);
        const d = U + sfW;
        const C = d + ftw;

        const T = d + bW;

        const S = C - xftw;

        const Z = C - tbW;
        const B = C + sfW;
        const f = A - tbW - (2 * sfW);
        const a = f;

        const e = U;
        const X = S + bW;
        const W = X + sfW;
        const V = W;

        const b = this.fix(e);
        const c = this.fix(d);
        const E = c + this.fix(fbw);
        const P = this.fix(T);
        const Q = E - this.fix(xfbw);
        const F = E + this.fix(sfW);
        const I = F - this.fix(2 * sfW + bW);
        const J = I + this.fix(sfW);
        const L = Q + this.fix(tbW);
        const M = L + this.fix(sfW);

        const G = F;
        const H = I;
        const O = b;
        const N = M;

        const fA = s(4, f, A, {startLabel: 'f', endLabel: 'A'});
        const BC = s(3, B, C, {startLabel: 'B', endLabel: 'C'});
        const EF = s(2, E, F, {startLabel: 'E', endLabel: 'F'});
        const GH = s(1, G, H, {startLabel: 'G', endLabel: 'H'});
        const IJ = s(2, I, J, {startLabel: 'I', endLabel: 'J'});
        const LM = s(2, L, M, {startLabel: 'L', endLabel: 'M'});
        const NO = s(1, N, O, {startLabel: 'N', endLabel: 'O'});
        const bc = s(2, b, c, {startLabel: 'b', endLabel: 'c'});
        const de = s(3, d, e, {startLabel: 'd', endLabel: 'e'});
        const UV = s(4, U, V, {startLabel: 'U', endLabel: 'V'});
        const WX = s(3, W, X, {startLabel: 'W', endLabel: 'X'});
        const Za = s(3, Z, a, {startLabel: 'Z', endLabel: 'a'});
        const PQ = s(2, P, Q, {startLabel: 'P', endLabel: 'Q'});
        const ST = s(3, S, T, {startLabel: 'S', endLabel: 'T'});

        const XE = Segment.betweenPoints(WX.end, EF.start);
        const SJ = Segment.betweenPoints(ST.start, IJ.end);
        const ZQ = Segment.betweenPoints(Za.start, PQ.end);
        const CL = Segment.betweenPoints(BC.end, LM.start);

        const Ypoint = XE.getIntersectionWith(ZQ);
        const Kpoint = SJ.getIntersectionWith(CL);
        const Dpoint = CL.getIntersectionWith(XE);
        const Rpoint = ZQ.getIntersectionWith(SJ);

        const Y  = Segment.betweenPoints(Ypoint, Ypoint);
        const K  = Segment.betweenPoints(Kpoint, Kpoint);
        const D  = Segment.betweenPoints(Dpoint, Dpoint);
        const R  = Segment.betweenPoints(Rpoint, Rpoint);

        this.segments = [
            [
                fA,
                BC,
                D,
                EF,
                GH,
                IJ,
                K,
                LM,
                NO,

                bc,
                de,

                UV,
                WX,
                Y,
                Za,
            ], [
                R,
                ST,
                PQ,
            ]
        ];

        this.boundingBoxCorners = [ UV.start, fA.end, GH.start, NO.end ];
    }
}

export default RomanNine;
