import React, { useCallback, useState, useEffect }  from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch }  from 'react-redux';
import { Accordion, Row, Col } from 'react-bootstrap';
import { Form, Button, ButtonGroup, InputGroup } from 'react-bootstrap';
import { LinkedInputs, BackgroundImageScaler, InlineImageField, Subtitle } from '@cartier-studio/components';
import {
    updateOpening, updateLayers, updateNumeralArea,
    updateNumerals, updateNumeralsCommon, updateSignature
} from '@cartier-studio/redux';
import Draggable from 'react-draggable';
import Icon from '@mdi/react';
import { mdiDragVariant } from '@mdi/js';
import _ from 'lodash';
import paper from 'paper';
import './style.scss';

const ProjectOptions = ({ project }) => {
    const [newWidth, setNewWidth] = useState(null);
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const debounceDuration = 1000;

    const onOpeningPathDataChange = data => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(data, 'text/xml');

        const update = { pathData: null, shape: 'custom', baseline: null };

        // detect viewport
        const root = xml.querySelector("svg");
        const [x, y, width, height] = (root?.getAttribute('viewBox') || '').split(' ');

        if (width > 0 && height > 0) {
            update.width  = Number(width);
            update.height = Number(height);
            update.center = {
                x: Number(width) / 2,
                y: Number(height) / 2,
                radius: 1
            };
        }

        // detect opening
        const opening = xml.querySelector("[id*='opening']");
        if (!opening)
            return;

        // get opening path data
        // we have to create a virtual canvas before to get access
        // to paper.project
        paper.setup(new paper.Size(1, 1));
        const shape = paper.project.importSVG(opening);
        const pathData = shape.toPath().pathData;
        if (!pathData)
            return;

        update.pathData = pathData;

        // try to detect baseline (inner shape)
        const baseline = xml.querySelector("[id*='baseline']");
        if (baseline) {
            // use the d attribute if we have one
            let baselinePathData = baseline.getAttribute('d') ?? "";
            if (!baselinePathData) {
                // import and compute the path data
                const shape = paper.project.importSVG(baseline);
                shape.rotate(180);
                baselinePathData = shape?.toPath().pathData;
            }
            if (baselinePathData)
                update.baseline = { pathData: baselinePathData };
        }

        return dispatch(updateOpening(update));
    }

    const onOpeningBackgroundChange = data => {
        return dispatch(updateOpening({ background: { ...opening.background, rfc2397_data: data }}));
    }

    const onOpeningBackgroundScaleChange = value => {
        return dispatch(updateOpening({ background: { ...opening.background, scale: value }}));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onOpeningChange = useCallback(_.debounce((e) => {
        const name  = e.target.name;
        const value = name != 'scaleType' ? parseFloat(e.target.value) : e.target.value;
        if (name.search('baseline.') === 0)
            dispatch(updateOpening({ baseline: { ...opening.baseline, [name.substring(9)]: value }}));
        else
            dispatch(updateOpening({ [name]: value }));
    }, debounceDuration, { leading: false }));

    const updateOpeningWidth = e => {
        if (Number(newWidth) > 0) {
            opening.setBoundingWidthMm(Number(newWidth));
            dispatch(updateOpening(opening));
            setNewWidth('');
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onNumeralAreaChange = useCallback(_.debounce((e) => {
        const name  = e.target.name;
        const value = parseFloat(e.target.value);
        if (name.search('center.') === 0)
            dispatch(updateNumeralArea({ center: { ...numeralArea.center, [name.substring(7)]: value }}));
        else
            dispatch(updateNumeralArea({ [name]: value }));
    }, debounceDuration, { leading: false }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onCommonChange = useCallback(_.debounce((e) => {
        const name  = e.target.name;
        const value = parseFloat(e.target.value);
        dispatch(updateNumeralsCommon({ [name]: value }));
    }, debounceDuration, { leading: false }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onNumeralChange = useCallback(_.debounce((e) => {
        const name  = e.target.name;
        const value = name === 'followOffsetAxis' ?
            (e.target.value === true || e.target.value === 'true') :
            parseFloat(e.target.value);
        dispatch(updateNumerals(layers.active, { [name]: value }));
    }, 200, { leading: false }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSignatureChange = useCallback(_.debounce((e) => {
        const name  = e.target.name;
        const value = parseFloat(e.target.value);
        if (name.search('transform.') === 0)
            dispatch(updateSignature({[signature.onNumeral]: { ...signature[signature.onNumeral], transform: { ...signature[signature.onNumeral].transform, [name.substring(10)]: value }}}));
        else if (name === 'onNumeral')
            dispatch(updateSignature({ [name]: value }));
        else
            dispatch(updateSignature({ [signature.onNumeral]: { ...signature[signature.onNumeral], [name]: value }}));
    }, 200, { leading: false }));

    const onSignatureBarsChange = useCallback(_.debounce((bars) => {
        const update = {
            [signature.onNumeral]: { ...signature[signature.onNumeral] }
        };
        bars.map(bar => {
            update[signature.onNumeral][bar.name] = bar.value;
        });
        dispatch(updateSignature(update));
    }, 200, { leading: false }));

    const toggleNumeral = index => {
        const visible = !(layers[index].visible);
        dispatch(updateLayers({ [index]: { visible }}));
    };

    const toggleNoneNumerals = () => {
        const update = {};
        // eslint-disable-next-line array-callback-return
        [...Array(12)].map((_,i) => { update[i + 1] = { active: layers[i + 1].active, 'visible': false }});
        dispatch(updateLayers(update));
    };

    const toggleAllNumerals = () => {
        const update = {};
        // eslint-disable-next-line array-callback-return
        [...Array(12)].map((_,i) => { update[i + 1] = { active: layers[i + 1].active, 'visible': true }});
        dispatch(updateLayers(update));
    };

    const indexToRoman = (i) => ['I', 'II', 'III', 'IIII', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII'][i - 1];

    const openingActualWidth = React.useMemo(() => {
        if (!project?.opening)
            return null;

        return project.opening.getBoundingBoxWidthMm().toFixed(2);
    }, [ project ]);

    if (!project || !project.numerals)
        return null;

    const { opening, layers, numeralArea, numerals: { common }, signature } = (project || {});
    const numerals = layers.active ? project.numerals[layers.active] : null;

    const numeralsOptions = [
        'fullTopWidth', 'fullBottomWidth',
        'xFullTopWidth', 'xFullBottomWidth',
        'spaceWidth', 'topBarsDst',
        'bottomOffset', 'offset', 'followOffsetAxis'
    ];

    return (
        <div className="ProjectOptions">
            <Draggable defaultPosition={{x: 0, y: 0}} grid={[5, 5]} scale={1} handle=".dragIcon">
                <div>
                    <Icon className="dragIcon" path={ mdiDragVariant } />
                    <Accordion defaultActiveKey="">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                Ouverture et centre
                            </Accordion.Header>
                            <Accordion.Body>
                                { opening && (
                                <>
                                    <Row>
                                        <Col>
                                            <Subtitle>
                                                Dimensions de l'ouverture
                                            </Subtitle>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={ 12 }>
                                            <div className="project-option">
                                                <div className="option-label">{ t(`options.opening.actual_width_mm`) }</div>
                                                <div className="option-input">
                                                    <InputGroup>
                                                        <Form.Control type="text" disabled value={ `${openingActualWidth} mm` } />
                                                        <Form.Control type="number" step="0.1" value={newWidth} onChange={e => setNewWidth(e.target.value)} placeholder={t('options.opening.new_width')}/>
                                                        <Button variant="primary" size="sm" onClick={ updateOpeningWidth }>
                                                            {t('options.opening.change_width')}
                                                        </Button>
                                                    </InputGroup>
                                                </div>
                                            </div>
                                        </Col>
                                        { /* }
                                        <Col sm={ 4 }>
                                            <div className="project-option">
                                                <div className="option-label">{ t(`options.opening.height`) }</div>
                                                <div className="option-input">
                                                    <Form.Control type="number" step="0.1" defaultValue={ opening.height } name="height" onChange={ onOpeningChange } />
                                                </div>
                                            </div>
                                        </Col>
                                        { */ }
                                    </Row>
                                    <br/>
                                </>
                                )}
                                <Row>
                                    <Col>
                                        <div className="project-option">
                                            <div className="option-label">{ t(`options.opening.title`) }</div>
                                            <InlineImageField name="opening.pathData" svg={true} image={ opening.pathData + ' ' + opening.baseline?.pathData } viewBox={{ width: opening.width, height: opening.height}} onChange={ onOpeningPathDataChange } asText />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="project-option">
                                            <div className="option-label">{ t(`options.opening.background`) }</div>
                                            <InlineImageField name="opening.background" image={ opening.background } onChange={ onOpeningBackgroundChange } />
                                            <BackgroundImageScaler project={ project } onChange={ onOpeningBackgroundScaleChange } />
                                        </div>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                Zone numérale
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col>
                                        <Subtitle>
                                            Options de la zone numérale
                                        </Subtitle>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="project-option">
                                            <div className="option-label">{ t('options.opening.scaleType') }</div>
                                            <div className="option-input">
                                                { ['offset', 'homothety'].map(type => (
                                                    <Form.Check
                                                        key={ type }
                                                        type="radio"
                                                        value={ type }
                                                        label={t(`options.opening.${ type }`) }
                                                        checked={ opening.scaleType === type }
                                                        name="scaleType"
                                                        onChange={ onOpeningChange }
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="project-option">
                                            <div className="option-label">{ t('options.numeralArea.margin') }</div>
                                            <div className="option-input">
                                                <Form.Control type="number" step="0.05" name="margin" defaultValue={ numeralArea?.margin } onChange={ onNumeralAreaChange } />
                                            </div>
                                        </div>
                                    </Col>
                                    { !opening?.baseline?.pathData && (
                                    <Col>
                                        <div className="project-option">
                                            <div className="option-label">{ t('options.numeralArea.height') }</div>
                                            <div className="option-input">
                                                <Form.Control type="number" step="0.05" name="height" defaultValue={ numeralArea?.height } onChange={ onNumeralAreaChange } />
                                            </div>
                                        </div>
                                    </Col>
                                    )}
                                </Row>
                                <br/>
                                <Row>
                                    <Col>
                                        <Subtitle>
                                            Décalage du centre
                                        </Subtitle>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="project-option">
                                            <div className="option-label">{ t(`options.numeralArea.center.xOffset`) }</div>
                                            <div className="option-input">
                                                <Form.Control type="number" step="0.1" defaultValue={ numeralArea.center.xOffset } name="center.xOffset" onChange={ onNumeralAreaChange } />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="project-option">
                                            <div className="option-label">{ t(`options.numeralArea.center.yOffset`) }</div>
                                            <div className="option-input">
                                                <Form.Control type="number" step="0.1" defaultValue={ numeralArea.center.yOffset } name="center.yOffset" onChange={ onNumeralAreaChange } />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                Chiffres romains
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col>
                                        <Subtitle>
                                            Options communes
                                        </Subtitle>
                                    </Col>
                                </Row>
                                <Row>
                                    { Object.keys(common || {}).map(key => (
                                    <Col key={ key } sm={ 6 }>
                                        <div className="project-option">
                                            <div className="option-label">{ t(`options.numeral.common.${key}`) }</div>
                                            <div className="option-input">
                                                <Form.Control type="number" step="0.05" name={ key } defaultValue={ common[key] } onChange={ onCommonChange } />
                                            </div>
                                        </div>
                                    </Col>
                                    ))}
                                </Row>
                                <br/>
                                <Row>
                                    <Col>
                                        <Subtitle>
                                            Romains visibles
                                        </Subtitle>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="project-option">
                                            <div className="option-label">Choisissez les romain visibles</div>
                                            <div className="option-input">
                                                <ButtonGroup className="layers-visibility">
                                                    <Button variant="dark" onClick={ toggleNoneNumerals } ><b>Aucun</b></Button>
                                                    { [...Array(12)].map((_,i) => (
                                                    <Button
                                                        key={ i }
                                                        variant={ layers[i + 1].visible ? 'primary' : 'secondary' }
                                                        onClick={() => { toggleNumeral(i + 1); }}
                                                    >
                                                        { indexToRoman(i + 1) }
                                                    </Button>
                                                    ))}
                                                    <Button variant="primary" onClick={ toggleAllNumerals } ><b>Tous</b></Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                Signature
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col sm={ 6 }>
                                        <Row>
                                            <Col>
                                                <Subtitle>
                                                    { t('options.signature.onNumeral') }
                                                </Subtitle>
                                            </Col>
                                        </Row>
                                        <div className="project-option">
                                            <div className="option-input">
                                                <Form.Check
                                                    type="radio"
                                                    value={ 7 }
                                                    label={t('options.signature.onSeven') }
                                                    checked={ signature.onNumeral === 7 }
                                                    name="onNumeral"
                                                    onChange={ onSignatureChange }
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    value={ 10 }
                                                    label={t('options.signature.onTen') }
                                                    checked={ signature.onNumeral === 10 }
                                                    name="onNumeral"
                                                    onChange={ onSignatureChange }
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-3">
                                        <Subtitle>
                                            { t('options.signature.bars_space') }
                                        </Subtitle>
                                    </Col>
                                </Row>
                                {signature.onNumeral === 7 && (
                                    <>
                                        <LinkedInputs
                                            names={['thinBarLength3', 'thinBarLength4']}
                                            labels={[t(`options.signature.thinBarLength1`), t(`options.signature.thinBarLength2`)]}
                                            defaultValues={[signature[signature.onNumeral].thinBarLength3, signature[signature.onNumeral].thinBarLength4]}
                                            onChange={onSignatureBarsChange}
                                            type="number"
                                            step={ 0.05 }
                                        />
                                        <LinkedInputs
                                            names={['thinBarLength1', 'thinBarLength2']}
                                            labels={[t(`options.signature.thinBarLength3`), t(`options.signature.thinBarLength4`)]}
                                            defaultValues={[signature[signature.onNumeral].thinBarLength1, signature[signature.onNumeral].thinBarLength2]}
                                            onChange={onSignatureBarsChange}
                                            type="number"
                                            step={ 0.05 }
                                        />
                                    </>
                                )}
                                {signature.onNumeral === 10 && (
                                    <>
                                        <LinkedInputs
                                            names={['thinBarLength1', 'thinBarLength2']}
                                            labels={[t(`options.signature.thinBarLength1`), t(`options.signature.thinBarLength2`)]}
                                            defaultValues={[signature[signature.onNumeral].thinBarLength1, signature[signature.onNumeral].thinBarLength2]}
                                            onChange={onSignatureBarsChange}
                                            type="number"
                                            step={ 0.05 }
                                        />
                                        <LinkedInputs
                                            names={['thinBarLength4', 'thinBarLength3']}
                                            labels={[t(`options.signature.thinBarLength3`), t(`options.signature.thinBarLength4`)]}
                                            defaultValues={[signature[signature.onNumeral].thinBarLength4, signature[signature.onNumeral].thinBarLength3]}
                                            onChange={onSignatureBarsChange}
                                            type="number"
                                            step={ 0.05 }
                                        />
                                        <LinkedInputs
                                            names={['thinBarLength8', 'thinBarLength7']}
                                            labels={[t(`options.signature.thinBarLength5`), t(`options.signature.thinBarLength6`)]}
                                            defaultValues={[signature[signature.onNumeral].thinBarLength1, signature[signature.onNumeral].thinBarLength2]}
                                            onChange={onSignatureBarsChange}
                                            type="number"
                                            step={ 0.05 }
                                        />
                                        <LinkedInputs
                                            names={['thinBarLength6', 'thinBarLength5']}
                                            labels={[t(`options.signature.thinBarLength7`), t(`options.signature.thinBarLength8`)]}
                                            defaultValues={[signature[signature.onNumeral].thinBarLength4, signature[signature.onNumeral].thinBarLength3]}
                                            onChange={onSignatureBarsChange}
                                            type="number"
                                            step={ 0.05 }
                                        />
                                    </>
                                )}
                                <Row>
                                    <Col className="mt-3">
                                        <Subtitle>
                                            { t('options.signature.position') }
                                        </Subtitle>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={ 6 }>
                                        <div className="project-option">
                                            <div className="option-label">{ t('options.signature.translate_x') }</div>
                                            <div className="option-input">
                                                <Form.Control type="number" step={ 0.05 } name={ 'transform.tx' } defaultValue={ signature[signature.onNumeral].transform.tx } onChange={ onSignatureChange } />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={ 6 }>
                                        <div className="project-option">
                                            <div className="option-label">{ t('options.signature.translate_y') }</div>
                                            <div className="option-input">
                                                <Form.Control type="number" step={ 0.05 } name={ 'transform.ty' } defaultValue={ signature[signature.onNumeral].transform.ty } onChange={ onSignatureChange } />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-3">
                                        <Subtitle>
                                            { t('options.signature.transformations') }
                                        </Subtitle>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={ 6 }>
                                        <div className="project-option">
                                            <div className="option-label">{ t(`options.signature.rotation`) }</div>
                                            <div className="option-input">
                                                <Form.Control type="number" step={ 0.05 } name="transform.r" defaultValue={ signature[signature.onNumeral].transform.r } onChange={ onSignatureChange } />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={ 6 }>
                                        <div className="project-option">
                                            <div className="option-label">{ t(`options.signature.scale`) }</div>
                                            <div className="option-input">
                                                <Form.Control type="number" step={ 0.05 } name="transform.scale" defaultValue={ signature[signature.onNumeral].transform.scale } onChange={ onSignatureChange } />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    { layers.active && (
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                Options spécifiques au romain { indexToRoman(layers.active) }
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    { numeralsOptions.filter(o => (o in numerals)).map(key => (
                                        <>
                                        { key !== 'followOffsetAxis' && (
                                            <>
                                            <Col key={ key } sm={ 6 }>
                                                <div className="project-option">
                                                    <div className="option-label">{ t(`options.numeral.numerals.${key}`) }</div>
                                                    <div className="option-input">
                                                        <Form.Control type="number" step={ key === 'offset' ? 1 : 0.05 } name={ key } defaultValue={ numerals[key] } onChange={ onNumeralChange } />
                                                    </div>
                                                </div>
                                            </Col>

                                            { key === 'offset' && (numerals.offset != 0) && (
                                                <Col key={ 'followOffsetAxis' } sm={ 6 }>
                                                    <div className="project-option">
                                                        <div className="option-label">{ t(`options.numeral.numerals.followOffsetAxis`) }</div>
                                                        <div className="option-input">
                                                            <Form.Select name="followOffsetAxis" defaultValue={numerals.followOffsetAxis ? 'true' : 'false'} onChange={ onNumeralChange }>
                                                                <option value={"true"}>{ t('options.numeral.numerals.onOffsetAxis') }</option>
                                                                <option value={"false"}>{ t('options.numeral.numerals.onOriginalAxis') }</option>
                                                            </Form.Select>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                            </>
                                        )}
                                        </>
                                    ))}
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    )}
                </div>
            </Draggable>
        </div>
    );
};

export default ProjectOptions;
