import React from 'react';
import Project from './Project';
import OpeningCenter from './OpeningCenter';
import Path from './Path';
import paper from 'paper';

/**
 * Class representing a round opening
 */
class Opening {
    constructor(properties: Object) {
        Object.assign(this, properties || {});

        if (this.center)
            this.center = new OpeningCenter(properties.center);
    }

    getBoundingBoxWidthMm() {
        return this.path?.bounds?.width || 0;
    }

    setBoundingWidthMm(newWidth: Number) {
        const actualWidth = this.getBoundingBoxWidthMm();
        const scale = newWidth / actualWidth;

        return this.applyScale(scale);
    }

    applyScale(scale: Number) {
        throw new Error('applyScale() should be overrided by sub class');
    }

    toJsx() {
        const size    = 125;
        const factor  = 20;
        const project = Project.getInstance();

        return (
            <g className="Opening">
                { project.viewOptions?.showBackground && this.background?.rfc2397_data && (
                    <svg
                        viewBox={ `0 0 ${size * factor} ${size * factor}` }
                        width={ this.background.scale * size * factor / 2 }
                        height={ this.background.scale * size * factor / 2 }
                    >
                        <image
                            className="backgroundImage"
                            xlinkHref={ this.background.rfc2397_data }
                            x={ (this.center.x / this.background.scale) * 2 }
                            y={ (this.center.y / this.background.scale) * 2 }
                            transform={ `scale(${this.background.scale})` }
                        />
                    </svg>
                )}

                <path
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.01"
                    className="opening-path"
                    d={ this.getPathData() }
                />

                { this.baseline?.pathData && (
                    <path
                        fill="none"
                        stroke="#000000"
                        strokeWidth="0.01"
                        className="opening-path"
                        d={ this.getMarker1Path() }
                    />
                )}

                { this.center.toJsx() }
            </g>
        );
    }

    toSvg() {
        const svg = [];
        svg.push('<g class="Opening">');
        svg.push(`<path fill="none" stroke="#000000" stroke-width="0.01" className="opening-path" d="${this.getPathData()}" />`);
        svg.push(this.center.toSvg());
        svg.push('</g>');

        return svg.join('');
    }

    /**
     * @return {Path}
     */
    static followOpeningSegment(a: Point, b: Point, markerPath: paper.Path): Path {
        const middlePoint = a.middleDistanceTo(b);
        const circle      = new paper.Path.Circle(middlePoint, (a.distanceTo(b) / 2).toFixed(2));
        const intersect   = markerPath.intersect(circle, { trace: false });

        return new Path(intersect.pathData);
    }

    getMarkerPath(index, offset) {
        switch (index) {
            case 1:
                return this.getMarker1Path(offset).pathData;
            case 2:
                return this.getMarker2Path(offset).pathData;
            case 3:
                return this.getMarker3Path(offset).pathData;
            case 4:
                return this.getMarker4Path(offset).pathData;
            default:
                return null;
        }
    }

    getPathData() {
        throw new Error('getPathData() should be overrided by sub class');
    }

    getMarker1Path(offset) {
        throw new Error('getMarker1Path(offset) should be overrided by sub class');
    }

    getMarker2Path(offset) {
        throw new Error('getMarker2Path(offset) should be overrided by sub class');
    }

    getMarker3Path(offset) {
        throw new Error('getMarker3Path(offset) should be overrided by sub class');
    }

    getMarker4Path(offset) {
        throw new Error('getMarker4Path(offset) should be overrided by sub class');
    }
}

export default Opening;
