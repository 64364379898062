import { Projects } from '@cartier-studio/services';
import { createRestSlices, applyReducers } from './rest';

const EXPORT            =  'cartier-studio/project/EXPORT';
const EXPORT_SUCCESS    =  'cartier-studio/project/EXPORT_SUCCESS';
const EXPORT_FAILURE    =  'cartier-studio/project/EXPORT_FAILURE';
const EXPORT_RESET      =  'cartier-studio/project/EXPORT_RESET';
const SET_ACTIVE_DIR    =  'cartier-studio/project/SET_ACTIVE_DIR';
const SET_CURRENT_PATHS =  'cartier-studio/project/SET_CURRENT_PATHS';

const {
    initialState,
    createReducer,
    startCreateReducer,
    getReducer,
    listReducer,
    updateReducer,
    deleteReducer,
    duplicateReducer,
    createAction,
    startCreateAction,
    getAction,
    listAction,
    updateAction,
    deleteAction,
    duplicateAction,
} = createRestSlices(Projects);

initialState.currentPaths = [];
initialState.activeDirectory = null;

/* Exportis reducer */
const exportReducer = (state, action) => {
    switch (action.type) {
            case EXPORT:
                return {
                    ...state,
                    isExporting: true,
                    hasExportingError: false,
                    exportResult: null,
                    exportErrorMessage: null
                };
            case EXPORT_SUCCESS:
                return {
                    ...state,
                    isExporting: false,
                    hasExportingError: false,
                    exportErrorMessage: null,
                    exportResult: action.result,
                };
            case EXPORT_FAILURE:
                return {
                    ...state,
                    isExporting: false,
                    hasExportingError: true,
                    exportResult: null,
                    exportErrorMessage: action.error
                };
            case EXPORT_RESET:
                return {
                    ...state,
                    isExporting: false,
                    hasExportingError: true,
                    exportResult: null,
                    exportErrorMessage: null
                };
            case SET_ACTIVE_DIR:
                return {
                    ...state,
                    activeDirectory: action.directory
                };
            case SET_CURRENT_PATHS:
                return {
                    ...state,
                    currentPaths: action.paths
                };
        default:
            return state;
    }
};
function exporting() { return { type: EXPORT } }
function exportSuccess(result) { return { type: EXPORT_SUCCESS, result} }
function exportFailure(err) { return { type: EXPORT_FAILURE, error: err } }
const exportAction = (projectId, format, callback) => dispatch => {
    dispatch(exporting());
    Projects.exportTo(projectId, format)
        .then(data => {
            dispatch(exportSuccess(data[format]));
            callback && callback(/*err*/null, data[format]);
        })
        .catch(err => {
            dispatch(exportFailure(err))
            callback && callback(err);
        });
}
const resetExportAction = () => dispatch => { dispatch({ type: EXPORT_RESET }); };

const setActiveDirectoryAction = directory => dispatch => { dispatch({ type: SET_ACTIVE_DIR, directory }); };
const setCurrentPathsAction = paths => dispatch => { dispatch({ type: SET_CURRENT_PATHS, paths }); };
/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        createReducer, startCreateReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer,
        duplicateReducer, exportReducer
    ]);
}

/* Export CRUD actions */
export const createProject      = createAction;
export const startCreateProject = startCreateAction;
export const loadProject        = getAction;
export const loadProjects       = listAction;
export const updateProject      = updateAction;
export const deleteProject      = deleteAction;
export const duplicateProject   = duplicateAction;
export const exportProject      = exportAction;
export const resetExport        = resetExportAction;
export const setActiveDirectory = setActiveDirectoryAction;
export const setCurrentPaths    = setCurrentPathsAction;
