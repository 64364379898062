import { Project } from '@cartier-studio/romans-lib';
import './style.scss';

const OpeningMarkers = ({ project }) => {
    if (!project || !project.viewOptions.showOpeningMarkers)
        return null;

    const { opening } = Project.getInstance();
    if (!opening)
        return null;

    return (
        <g className="OpeningMarkers">
            <path
                className="numeral-area c4"
                d={ opening.getMarker4Path(0).pathData }
                fill="none"
                stroke="#000000"
                strokeWidth="0.01"
            />
            <path
                className="numeral-area c3"
                d={ opening.getMarker3Path(0).pathData }
                fill="none"
                stroke="#000000"
                strokeWidth="0.01"
            />
            <path
                className="numeral-area c2"
                d={ opening.getMarker2Path(0).pathData }
                fill="none"
                stroke="#000000"
                strokeWidth="0.01"
            />
            <path
                className="numeral-area c1"
                d={ opening.getMarker1Path(0).pathData }
                fill="none"
                stroke="#000000"
                strokeWidth="0.01"
            />
        </g>
    );
};

export default OpeningMarkers;
