import RestClient from './RestClient';
import { Directory } from '@cartier-studio/models';

class Directories extends RestClient {
    constructor() {
        super({
            // rest config
            model: Directory,
            entryPoint: 'directories',
            sortBy: 'name',
            id_field: '_id',
            // redux config
            resource: 'directory',
            resources: 'directories',
        });
    }
}

const DirectoriesApi = new Directories();

export default DirectoriesApi;
