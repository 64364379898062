import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Modal, Offcanvas, Row, Col } from 'react-bootstrap';
import { Project } from '../../editor';
import { ProjectDetails, ProjectExporter, ProjectsExplorer } from '../../projects';
import { UsersList } from '../../users';

import './MainContent.scss';

const MainContent = ({ project }) => {
    const { user } = useSelector(state => state.auth);

    const [showExplorer, setShowExplorer] = useState(false);
    const closeExplorer = () => setShowExplorer(false);
    const [showProjectDetails, setShowProjectDetails] = useState(false);
    const closeProjectDetails = () => setShowProjectDetails(false);
    const [showExporter, setShowExporter] = useState(false);
    const closeExporter = () => setShowExporter(false);

    return (
        <Container fluid className="MainContent">
            { !project && (
                <Row>
                    <Col>
                        <Modal.Dialog className="ProjectsExplorer" size="lg">
                            <Modal.Body>
                                <ProjectsExplorer />
                            </Modal.Body>
                        </Modal.Dialog>
                    </Col>
                    { user?.isSuperAdmin() &&  (
                    <Col>
                        <Modal.Dialog className="UsersList" size="lg">
                            <Modal.Body>
                                <UsersList />
                            </Modal.Body>
                        </Modal.Dialog>
                    </Col>
                    )}
                </Row>
            )}

            { project && (
                <Offcanvas show={ showProjectDetails } onHide={ closeProjectDetails } placement="start">
                    <Offcanvas.Body>
                        <ProjectDetails />
                    </Offcanvas.Body>
                </Offcanvas>
            )}

            { project && (
                <Project
                    project={ project }
                    showProjectDetails={() => setShowProjectDetails(true) }
                    showExplorer={() => setShowExplorer(true) }
                    showExporter={() => setShowExporter(true) }
                />
            )}

            { project && (
                <Offcanvas show={ showExporter } onHide={ closeExporter } placement="end">
                    <Offcanvas.Body>
                        <ProjectExporter project={ project } />
                    </Offcanvas.Body>
                </Offcanvas>
            )}

            { project && (
                <Offcanvas show={ showExplorer } onHide={ closeExplorer } placement="end">
                    <Offcanvas.Body>
                        <ProjectsExplorer inSidebar/>
                    </Offcanvas.Body>
                </Offcanvas>
            )}
        </Container>
    );
}

export default MainContent;
