import { Directories } from '@cartier-studio/services';
import { createRestSlices, applyReducers } from './rest';

const EXPORT         =  'cartier-studio/directory/EXPORT';
const EXPORT_SUCCESS =  'cartier-studio/directory/EXPORT_SUCCESS';
const EXPORT_FAILURE =  'cartier-studio/directory/EXPORT_FAILURE';
const EXPORT_RESET   =  'cartier-studio/directory/EXPORT_RESET';

const {
    initialState,
    createReducer,
    startCreateReducer,
    getReducer,
    listReducer,
    updateReducer,
    deleteReducer,
    duplicateReducer,
    createAction,
    startCreateAction,
    getAction,
    listAction,
    updateAction,
    deleteAction,
    duplicateAction,
} = createRestSlices(Directories);

/* Exportis reducer */
const exportReducer = (state, action) => {
    switch (action.type) {
            case EXPORT:
                return {
                    ...state,
                    isExporting: true,
                    hasExportingError: false,
                    exportResult: null,
                    exportErrorMessage: null
                };
            case EXPORT_SUCCESS:
                return {
                    ...state,
                    isExporting: false,
                    hasExportingError: false,
                    exportErrorMessage: null,
                    exportResult: action.result,
                };
            case EXPORT_FAILURE:
                return {
                    ...state,
                    isExporting: false,
                    hasExportingError: true,
                    exportResult: null,
                    exportErrorMessage: action.error
                };
            case EXPORT_RESET:
                return {
                    ...state,
                    isExporting: false,
                    hasExportingError: true,
                    exportResult: null,
                    exportErrorMessage: null
                };
        default:
            return state;
    }
};
function exporting() { return { type: EXPORT } }
function exportSuccess(result) { return { type: EXPORT_SUCCESS, result} }
function exportFailure(err) { return { type: EXPORT_FAILURE, error: err } }
const exportAction = (directoryId, format, callback) => dispatch => {
    dispatch(exporting());
    Directories.exportTo(directoryId, format)
        .then(data => {
            dispatch(exportSuccess(data[format]));
            callback && callback(/*err*/null, data[format]);
        })
        .catch(err => {
            dispatch(exportFailure(err))
            callback && callback(err);
        });
}
const resetExportAction = () => dispatch => { dispatch({ type: EXPORT_RESET }); };

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        createReducer, startCreateReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer,
        duplicateReducer, exportReducer
    ]);
}

/* Export CRUD actions */
export const createDirectory      = createAction;
export const startCreateDirectory = startCreateAction;
export const loadDirectory        = getAction;
export const loadDirectories       = listAction;
export const updateDirectory      = updateAction;
export const deleteDirectory      = deleteAction;
export const duplicateDirectory   = duplicateAction;
export const exportDirectory      = exportAction;
export const resetExport        = resetExportAction;
