import Roman from './Roman';
import Project from './Project';
import Segment from './Segment';

/**
 * Class representing a roman 3 number
 *
 *           L_______________________________A
 *           |                               |
 *           K____J   P____M   T____Q   C____B
 *                |   |    |   |    |   |
 *                |   |    |   |    |   |
 *                |   |    |   |    |   |
 *                |   |    |   |    |   |
 *                |   |    |   |    |   |
 *                |   |    |   |    |   |
 *           H____I   O____N   S____R   D____E
 *           |                               |
 *           G_______________________________F
 */
class RomanThree extends Roman {
    constructor() {
        super(/*index*/3);
    }

    buildSegments() {
        const project = Project.getInstance();
        if (!project.numerals)
            return;

        const serifWidth = project.numerals.common.serifWidth;
        const barsWidth  = project.numerals.common.barsWidth;
        const spaceWidth = project.numerals[this.positionIndex].spaceWidth;
        const radialOffset = project.numerals[this.positionIndex].followOffsetAxis ? 0 : project.numerals[this.positionIndex].offset / 2.6;

        // shortcut
        const s = (marker, first, last, data) => Segment.onOpening(3, marker, first, last, /*xOffset*/0, /*yOffset*/0, /*radialOffset*/0, data);

        const A = 1.5 * barsWidth + spaceWidth + serifWidth + radialOffset;
        const L = -1 * A + (2 * radialOffset);
        const B = A;
        const C = B - serifWidth;
        const D = C;
        const E = D + serifWidth;
        const F = E;
        const G = L;
        const H = G;
        const I = H + serifWidth;
        const J = I;
        const K = L;

        const LA = s(4, L, A, {startLabel: 'L', endLabel: 'A'});
        const BC = s(3, B, C, {startLabel: 'B', endLabel: 'C'});
        const DE = s(2, D, E, {startLabel: 'D', endLabel: 'E'});
        const FG = s(1, F, G, {startLabel: 'F', endLabel: 'G'});
        const HI = s(2, H, I, {startLabel: 'H', endLabel: 'I'});
        const JK = s(3, J, K, {startLabel: 'J', endLabel: 'K'});

        const P = L + barsWidth + serifWidth;
        const M = P + spaceWidth;
        const N = M;
        const O = P;
        const T = M + barsWidth;
        const Q = T + spaceWidth;
        const R = Q;
        const S = T;

        const MP = s(3, M, P, {startLabel: 'M', endLabel: 'P'});
        const ON = s(2, O, N, {startLabel: 'O', endLabel: 'N'});
        const QT = s(3, Q, T, {startLabel: 'Q', endLabel: 'T'});
        const SR = s(2, S, R, {startLabel: 'S', endLabel: 'R'});

        this.segments = [
            // curvedSegments (follow the numeral area path)
            [ LA, BC, DE, FG, HI, JK ],
            // centerLeftSegments
            [ MP, ON, ],
            // centerRightSegments
            [ QT, SR ]
        ];

        this.boundingBoxCorners = [ LA.start, LA.end, FG.start, FG.end ];
    }
}

export default RomanThree;
