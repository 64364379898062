import React from 'react';
import { ViewerOptions, ZoomSlider, RotationSlider } from '@cartier-studio/components';
import { Container, Row, Col } from 'react-bootstrap';
import './style.scss';

const Footer = ({ project }) => {
    return (
        <footer className="Footer">
            <Container fluid>
                <Row>
                    <Col className="toolsWrapper">
                        { project && (
                            <>
                                <div className="footer-tool">
                                    <ZoomSlider project={ project } />
                                </div>
                                <div className="footer-tool">
                                    <RotationSlider project={ project } />
                                </div>
                                <div className="footer-tool">
                                    <ViewerOptions project={ project } />
                                </div>
                            </>
                        )}
                    </Col>
                    <Col className="infosWrapper" sm="auto">
                        <span className="infos">
                            Cartier Studio - Tour de chiffres romains - V1.0.0
                        </span>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
