import './style.scss';

const Title = ({ children }) => {
    return (
        <h1 className="Title">
            { children }
        </h1>
    );
};

export default Title;
