import Client from './Client';
import { isExpired } from "react-jwt";

class Auth extends Client {
    checkJwt(token) {
        return new Promise((resolve, reject) => { resolve(!isExpired(token)); });;
    }

    logIn(email, password) {
        return this.POST('/users/login', {
            email,
            password
        })
        .then(response => response.json())
        .then(json => {
            if (json.user) {
                localStorage.setItem('user', JSON.stringify(json.user));
                return json;
            }
            throw new Error(json.flash.error);
        });
    }

    logOut() {
        return this.POST('/users/logout').then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error("Déconnexion impossible");
        });
    }
}

const AuthApi = new Auth();

export default AuthApi;
