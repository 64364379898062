import React from 'react';
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next';
import { Alert, Button, FormGroup } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiLoading, mdiLoginVariant } from '@mdi/js';

import logo  from '../../../assets/images/logo.svg';
import './LoginForm.scss';

const LoginForm = ({ onSubmit, pristine, isLoggingIn, logInError, logInErrorMessage }) => {
    const { t } = useTranslation();

    return (
        <div className="LoginForm">
            { logInError && <Alert className="mt-4" variant="danger">{ logInErrorMessage }</Alert> }
            <Form onSubmit={ onSubmit } className="form-login" render={ ({ handleSubmit }) => (
                <form className="login-form" onSubmit={handleSubmit}>
                    <img className="logo" src={ logo } alt="logo" />
                    <br/>
                    <FormGroup>
                        <Field component="input" className="form-control" placeholder="Email" name="email" id="email" required />
                    </FormGroup>
                    <FormGroup>
                        <Field component="input" type="password" className="form-control" placeholder="Password" name="password" id="password" required />
                    </FormGroup>
                    <FormGroup>
                        <Button type="submit" color="dark" className="btn-block" disabled={pristine || isLoggingIn}>
                            { !isLoggingIn && <Icon path={ mdiLoginVariant } size={ 1 } color="white" /> }
                            { isLoggingIn && <Icon path={ mdiLoading } size={ 1 } spin={ 1 } color="white" /> }
                            &nbsp;{ t('login.connect') }
                        </Button>
                    </FormGroup>
                </form>
            )} />
        </div>
    );
}

export default LoginForm;
