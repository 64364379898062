import Roman from './Roman';
import Project from './Project';
import Segment from './Segment';

/**
 * Class representing a roman 8 number
 *
 *           G________H     N__________________________________________O
 *           |        |     |                                          |
 *           F__E  J__I     M__L C__________X   a____b   e____f   Q____P
 *              \   \          //           |   |    |   |    |   |
 *               \   \        //            |   |    |   |    |   |
 *                \   \      //             |   |    |   |    |   |
 *                 \   \    //              |   |    |   |    |   |
 *                  \   \ K//               |   |    |   |    |   |
 *                   \   \//           V____W   d____c   h____g   R____S
 *                    \   /            |                               |
 *                     \ /             U_______________________________T
 *                      D
 *                     / \             ^
 *                    /   \            |        ^
 *                   /     \           iOffset  |        ^
 *                  Y       Z                iiOfset     |
 *                                                       iiiOffset
 */
class RomanEight extends Roman {
    constructor() {
        super(/*index*/8);
    }

    buildSegments() {
        const project = Project.getInstance();
        if (!project.numerals)
            return;

        const { serifWidth, barsWidth, thinBarsWidth } = project.numerals.common;
        const {
            fullTopWidth, fullBottomWidth,
            bottomOffset, topBarsDst,
            spaceWidth, offset, followOffsetAxis
        } = project.numerals[this.positionIndex];

        const radialOffset = followOffsetAxis ? 0 : offset / 2.6;

        // shortcut
        const s = (marker, first, last, yOffset, data) => Segment.onOpening(8, marker, first, last, /*xOffset*/0, yOffset, /*radialOffset*/0, data);

        const O = fullTopWidth / 2 + serifWidth + radialOffset;
        const G = -1 * O + (2 * radialOffset);
        const F = G;
        const E = F + serifWidth;
        const H = G + 2 * serifWidth + barsWidth;
        const I = H;
        const J = I - serifWidth;

        const N = H + topBarsDst;
        const M = N;
        const L = M + serifWidth;
        const C = L + thinBarsWidth;
        const P = O;
        const Q = P - serifWidth;
        const R = Q;
        const S = R + serifWidth;
        const T = S;
        const U = T - 3 * barsWidth - 2 * spaceWidth - 2 * serifWidth;
        const V = U;
        const W = V + serifWidth;
        const X = W;

        const a = X + barsWidth;
        const b = a + spaceWidth;
        const d = a;
        const c = b;

        const e = b + barsWidth;
        const f = e + spaceWidth;
        const h = e;
        const g = f;

        const DPos = T - fullBottomWidth;
        const D    = s(1, DPos, DPos, bottomOffset, {startLabel: 'D'});

        // Five
        const EF = s(3, E, F, 0, {startLabel: 'E', endLabel: 'F'});
        const GH = s(4, G, H, 0, {startLabel: 'G', endLabel: 'H'});
        const IJ = s(3, I, J, 0, {startLabel: 'I', endLabel: 'J'});
        const LM = s(3, L, M, 0, {startLabel: 'L', endLabel: 'M'});
        const NO = s(4, N, O, 0, {startLabel: 'N', endLabel: 'O'});

        // One
        const PQ = s(3, P, Q, 0, {startLabel: 'P', endLabel: 'Q'});
        const RS = s(2, R, S, 0, {startLabel: 'R', endLabel: 'S'});
        const TU = s(1, T, U, 0, {startLabel: 'T', endLabel: 'U'});
        const VW = s(2, V, W, 0, {startLabel: 'V', endLabel: 'W'});
        const XC = s(3, X, C, 0, {startLabel: 'X', endLabel: 'c'});

        const ba = s(3, b, a, 0, {startLabel: 'b', endLabel: 'a'});
        const dc = s(2, d, c, 0, {startLabel: 'd', endLabel: 'c'});

        const fe = s(3, f, e, 0, {startLabel: 'f', endLabel: 'e'});
        const hg = s(2, h, g, 0, {startLabel: 'h', endLabel: 'g'});

        const YZ = s(1, DPos - thinBarsWidth, DPos + barsWidth, bottomOffset, {startLabel: 'Y', endLabel: 'Z'});

        const Y = YZ.start;
        const Z = YZ.end;
        const JZ = Segment.betweenPoints(IJ.end, Z, 0, {startLabel: 'J', endLabel: 'Z'});
        const LY = Segment.betweenPoints(LM.start, Y, 0, {startLabel: 'L', endLabel: 'Y'});

        const intersection = LY.getIntersectionWith(JZ);

        const K = Segment.betweenPoints(intersection, intersection);
        this.segments = [
            [
                D,
                EF,
                GH,
                IJ,
                K,
                LM,
                NO,
                PQ,
                RS,
                TU,
                VW,
                XC,
            ],
            [
                ba,
                dc
            ],
            [
                fe,
                hg
            ]
        ];

        this.boundingBoxCorners = [ GH.start, NO.end, TU.start, D.start ];
    }
}

export default RomanEight;
