import './style.scss';

const Subtitle = ({ children }) => {
    return (
        <h4 className="Subtitle">
            { children }
        </h4>
    );
};

export default Subtitle;
