import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button  } from 'react-bootstrap';
import { exportProject, resetExport } from '@cartier-studio/redux';
import { Subtitle } from '../../typo';
import Icon from '@mdi/react';
import { dxf, png, svg } from '@cartier-studio/assets';
import { mdiDownload, mdiLoading } from '@mdi/js';

import './ProjectExporter.scss';

const ProjectExporter = () => {
    const [exportType, setExportType] = useState(null);

    const { project, isExporting, exportResult } = useSelector(state => state.projects);
    const { t } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        if (exportType && exportResult) {
            const link = document.createElement('a');
            switch (exportType) {
                case 'svg':
                    link.setAttribute('href', `data:image/svg+xml;charset=utf-8,${encodeURIComponent(exportResult)}`);
                    link.setAttribute('download', `${project.name}.svg`);
                    break;
                case 'png':
                    link.setAttribute('href', (exportResult));
                    link.setAttribute('download', `${project.name}.png`);
                    break;
                case 'dxf':
                default:
                    link.setAttribute('href', `data:application/dxf;charset=utf-8,${encodeURIComponent(exportResult)}`);
                    link.setAttribute('download', `${project.name}.dxf`);
                    break;
            }
            document.body.appendChild(link);
            link.click();
            dispatch(resetExport());
            setExportType(null);
            link.remove();
        }
    }, [ dispatch, exportResult, project.name, exportType ]);

    const exportDxf = () => {
        setExportType('dxf');
        dispatch(exportProject(project._id, 'dxf'));
    }

    const exportSvg = () => {
        setExportType('svg');
        dispatch(exportProject(project._id, 'svg'));
    }

    const exportPng = () => {
        setExportType('png');
        dispatch(exportProject(project._id, 'png'));
    }

    if (!project)
        return null;

    return (
        <div className="ProjectExporter">
            <Subtitle>{ t('projects.export_this_project') }</Subtitle>
            <Card className="export-wrapper">
                <Card.Img variant="left" src={ dxf } />
                <Card.Body>
                    <Card.Title>DXF</Card.Title>
                    <Card.Text>{ t('projects.dxf_export_description') }</Card.Text>
                    <Button onClick={ exportDxf }>
                        { isExporting && (<Icon path={ mdiLoading } spin size={ 1 } color="white" />) }
                        { !isExporting && (<Icon path={ mdiDownload } size={ 1 } color="white" />) }
                        &nbsp;{ t('projects.export') }
                    </Button>
                </Card.Body>
            </Card>
            <Card className="export-wrapper">
                <Card.Img variant="left" src={ svg } />
                <Card.Body>
                    <Card.Title>SVG</Card.Title>
                    <Card.Text>{ t('projects.svg_export_description') }</Card.Text>
                    <Button onClick={ exportSvg }>
                        { isExporting && (<Icon path={ mdiLoading } spin size={ 1 } color="white" />) }
                        { !isExporting && (<Icon path={ mdiDownload } size={ 1 } color="white" />) }
                        &nbsp;{ t('projects.export') }
                    </Button>
                </Card.Body>
            </Card>
            <Card className="export-wrapper">
                <Card.Img variant="left" src={ png } />
                <Card.Body>
                    <Card.Title>PNG</Card.Title>
                    <Card.Text>{ t('projects.png_export_description') }</Card.Text>
                    <Button onClick={ exportPng }>
                        { isExporting && (<Icon path={ mdiLoading } spin size={ 1 } color="white" />) }
                        { !isExporting && (<Icon path={ mdiDownload } size={ 1 } color="white" />) }
                        &nbsp;{ t('projects.export') }
                    </Button>
                </Card.Body>
            </Card>
        </div>
    );
}

export default ProjectExporter;
