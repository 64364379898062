import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Project } from '@cartier-studio/romans-lib';
import { Header, MainContent, Footer, FullPageLoader } from '@cartier-studio/components';
import { getLastConnectedUser } from '@cartier-studio/redux';
import { Login } from '../Login';

import './App.scss';

import moment from 'moment'
import 'moment/locale/fr';
moment.locale('fr');

const App = () => {
    const { project }                = useSelector(state => state.editor);
    const { user, isAuthenticating } = useSelector(state => state.auth);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLastConnectedUser());
    }, [dispatch]);

    useEffect(() => {
        // Ensure first initialization of the Project.
        // Next updates are done by the redux sync middleware
        if (!Project.isInitialized())
            Project.setup(project);
    }, [project]);

    if (isAuthenticating) {
        return <FullPageLoader />;
    }

    if (!user || !('_id' in user)) {
        return <Login />;
    }

    return (
        <div className="App">
            <Header project={ project } />
            <MainContent project={ project }/>
            <Footer project={ project } />
        </div>
    );
}

export default App;
