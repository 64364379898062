import Roman from './Roman';
import Project from './Project';
import Segment from './Segment';

/**
 * Class representing a roman 5 number
 *
 *           G________H     N__________A
 *           |        |     |          |
 *           F__E  J__I     M__L C_____B
 *              \   \          //
 *               \   \        //
 *                \   \      //
 *                 \   \    //
 *                  \   \ K//
 *                   \   \//
 *                    \   /
 *                     \ /
 *                    O  D   P
 */
class RomanFive extends Roman {
    constructor() {
        super(/*index*/5);
    }

    buildSegments() {
        const project = Project.getInstance();
        if (!project.numerals)
            return;

        const { serifWidth, barsWidth, thinBarsWidth } = project.numerals.common;
        const { bottomOffset, topBarsDst, followOffsetAxis, offset } = project.numerals[this.positionIndex];
        const radialOffset = followOffsetAxis ? 0 : offset / 2.6 + 0.0001;

        // shortcut
        const s = (marker, first, last, yOffset, data) => Segment.onOpening(5, marker, first, last, /*xOffset*/0, yOffset, /*radialOffset*/radialOffset, data);

        const fullWidth = barsWidth + thinBarsWidth + topBarsDst + 2 * serifWidth;

        const A = fullWidth / 2 + serifWidth + radialOffset;
        const G = -1 * A + (2 * radialOffset);
        const H = G + 2 * serifWidth + barsWidth;
        const N = H + topBarsDst;
        const F = G;
        const E = F + serifWidth;
        const I = H;
        const J = E + barsWidth;
        const B = A;
        const C = B - serifWidth;
        const M = N;
        const L = M + serifWidth;

        const vCenter = 0.00001;//A / 2;
        const D = s(1, vCenter - 0.0001, vCenter + 0.0001, bottomOffset, {startLabel: 'D'});

        const NA = s(4, N, A, 0, {startLabel: 'N', endLabel: 'A'});
        const BC = s(3, B, C, 0, {startLabel: 'B', endLabel: 'C'});
        const EF = s(3, E, F, 0, {startLabel: 'E', endLabel: 'F'});
        const GH = s(4, G, H, 0, {startLabel: 'G', endLabel: 'H'});
        const IJ = s(3, I, J, 0, {startLabel: 'I', endLabel: 'J'});
        const LM = s(3, L, M, 0, {startLabel: 'L', endLabel: 'M'});

        const OP = s(1, -1 * thinBarsWidth + vCenter, barsWidth + vCenter, bottomOffset);

        const O = OP.start;
        const P = OP.end;
        const JP = Segment.betweenPoints(IJ.end, P);
        const LO = Segment.betweenPoints(LM.start, O);

        const intersection = LO.getIntersectionWith(JP);

        const K = Segment.betweenPoints(intersection, intersection);

        this.segments = [
            [
                NA,
                BC,
                D,
                EF,
                GH,
                IJ,
                K,
                LM,
            ]
        ];

        this.boundingBoxCorners = [ GH.start, NA.end, D.start ];
    }
}

export default RomanFive;
