import Roman from './Roman';
import Project from './Project';
import Segment from './Segment';

/**
 * Class representing a roman 11 number
 *
 *           U________V     b_________________________A
 *           |        |     |                         |
 *           T__S  X__W     a__Z e_________d   C______B
 *               \  \          //          |   |
 *                \  \        //           |   |
 *                 \  \      //            |   |
 *                  \  \    //             |   |
 *                   \  \  //              |   |
 *                    \   Y/               |   |
 *                      R  D               |   |
 *                      /  \               |   |
 *                     / K  \              |   |
 *                    //  \  \             |   |
 *                   //    \  \            |   |
 *                  //      \  \           |   |
 *            P____QL__M  I__J  \E_________c   f______F
 *            |        |  |                           |
 *            O________N  H___________________________G
 */
class RomanEleven extends Roman {
    constructor() {
        super(/*index*/11);
    }

    buildSegments() {
        const project = Project.getInstance();
        if (!project.numerals)
            return;

        const common   = project.numerals.common;
        const numerals = project.numerals[this.positionIndex];
        const sfW = common.serifWidth;
        const bW  = common.barsWidth;
        const tbW = common.thinBarsWidth;
        const ftw  = numerals.fullTopWidth;
        const fbw  = numerals.fullBottomWidth + 0.0001;
        const xftw = numerals.xFullTopWidth;
        const xfbw = numerals.xFullBottomWidth;
        const radialOffset = project.numerals[this.positionIndex].followOffsetAxis ? 0 : project.numerals[this.positionIndex].offset / 2.6;

        // shortcut
        const s = (marker, first, last, data) => Segment.onOpening(11, marker, first, last, /*xOffset*/0, /*yOffset*/0, /*radialOffset*/0, data);

        const A = ftw / 2 + sfW + radialOffset;
        const U = -1 * A + (2 * radialOffset);
        const V = U + bW + 2 * sfW;
        const T = U;
        const S = T + sfW;
        const X = S + bW;
        const W = V;

        const e = S + xftw;
        const Z = e - tbW;
        const a = Z - sfW;
        const b = a;

        const C = S + ftw;
        const d = C - bW;
        const B = C + sfW;

        const P = T;
        const O = P;
        const Q = P + sfW;
        const L = Q + tbW;
        const M = L + sfW;
        const N = M;
        const E = Q + xfbw;
        const J = E - bW;
        const I = J - sfW;
        const H = I;
        const f = C;
        const c = d;
        const F = f + sfW;
        const G = F;

        const bA = s(4, b, A, {startLabel: 'b', endLabel: 'A'});
        const BC = s(3, B, C, {startLabel: 'B', endLabel: 'C'});
        const fF = s(2, f, F, {startLabel: 'f', endLabel: 'F'});
        const GH = s(1, G, H, {startLabel: 'G', endLabel: 'H'});
        const IJ = s(2, I, J, {startLabel: 'I', endLabel: 'J'});
        const LM = s(2, L, M, {startLabel: 'L', endLabel: 'M'});
        const NO = s(1, N, O, {startLabel: 'N', endLabel: 'O'});
        const PQ = s(2, P, Q, {startLabel: 'P', endLabel: 'Q'});
        const ST = s(3, S, T, {startLabel: 'S', endLabel: 'T'});
        const UV = s(4, U, V, {startLabel: 'U', endLabel: 'V'});
        const WX = s(3, W, X, {startLabel: 'W', endLabel: 'X'});
        const Za = s(3, Z, a, {startLabel: 'Z', endLabel: 'a'});
        const Ec = s(2, E, c, {startLabel: 'E', endLabel: 'c'});
        const de = s(3, d, e, {startLabel: 'd', endLabel: 'e'});

        const XE = Segment.betweenPoints(WX.end, Ec.start);
        const SJ = Segment.betweenPoints(ST.start, IJ.end);
        const ZQ = Segment.betweenPoints(Za.start, PQ.end);
        const Le = Segment.betweenPoints(LM.start, de.end);

        const Ypoint = XE.getIntersectionWith(ZQ);
        const Kpoint = SJ.getIntersectionWith(Le);
        const Dpoint = Le.getIntersectionWith(XE);
        const Rpoint = ZQ.getIntersectionWith(SJ);

        const Y  = Segment.betweenPoints(Ypoint, Ypoint);
        const K  = Segment.betweenPoints(Kpoint, Kpoint);
        const D  = Segment.betweenPoints(Dpoint, Dpoint);
        const R  = Segment.betweenPoints(Rpoint, Rpoint);

        this.segments = [
            [
                bA,
                BC,
                fF,
                GH,
                IJ,
                K,
                LM,
                NO,
                PQ,
                R,
                ST,
                UV,
                WX,
                Y,
                Za,
            ], [
                de,
                D,
                Ec,
            ]
        ];

        this.boundingBoxCorners = [ UV.start, bA.end, GH.start, NO.end ];
    }
}

export default RomanEleven;
