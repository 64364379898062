import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import './InlineImageField.scss';

const InlineImageField = ({ name, image, onChange, asText, svg, viewBox }) => {
    const { t } = useTranslation();
    const [ data, setData ] = useState(image?.rfc2397_data);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')

            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                setData(reader.result);
                if (onChange)
                    onChange(reader.result);
            }
            if (asText)
                reader.readAsText(file)
            else
                reader.readAsDataURL(file)
        })
    }, [])

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({ onDrop });

    const scale = image && svg && viewBox ? Math.min(
        viewBox?.width / 297 * 6,
        viewBox?.height / 210 * 6
    ) : 0;
    return (
        <div className="InlineImageField">
            <div className="img-wrapper">
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>{ t('common.drag_one_file') }</p>
                    { data && !svg && (
                        <img top width="100%" src={ data } alt="Card image cap" />
                    )}
                    { image && svg && viewBox && (
                    <svg viewBox={`0 0 ${viewBox.width} ${viewBox.height}`} transform={`scale(${6})`}>
                            <path d={ image } />
                        </svg>
                    )}
                </div>
                <input type="hidden" name={ `${name}.rfc2397_data` } value={ data } />
            </div>
        </div>
    );
}

export default InlineImageField;
