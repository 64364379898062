import { Users } from '@cartier-studio/services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState,
    createReducer,
    startCreateReducer,
    getReducer,
    listReducer,
    updateReducer,
    createAction,
    startCreateAction,
    getAction,
    listAction,
    updateAction,
} = createRestSlices(Users);

/* Const */
const DISABLING_USER         = 'pixoo/users/DISABLING_USER';
const DISABLING_USER_FAILURE = 'pixoo/users/DISABLING_USER_FAILURE';
const DISABLING_USER_SUCCESS = 'pixoo/users/DISABLING_USER_SUCCESS';
const ENABLING_USER          = 'pixoo/users/ENABLING_USER';
const ENABLING_USER_FAILURE  = 'pixoo/users/ENABLING_USER_FAILURE';
const ENABLING_USER_SUCCESS  = 'pixoo/users/ENABLING_USER_SUCCESS';

/* Reducers */
const enableReducer = (state, action) => {
    switch (action.type) {
        case ENABLING_USER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case ENABLING_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                user: action.user
            };
        case ENABLING_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
}

const disableReducer = (state, action) => {
    switch (action.type) {
        case DISABLING_USER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DISABLING_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                user: action.user
            };
        case DISABLING_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
}

/* Actions */
function disablingUser() { return { type: DISABLING_USER } }
function disablingUserSuccess(user) { return { type: DISABLING_USER_SUCCESS, user: user } }
function disablingUserFailure(err) { return { type: DISABLING_USER_FAILURE, error: err } }
const disableAction = (user) => {
    return (dispatch) => {
        dispatch(disablingUser());
        Users.disable(user.id)
            .then(data => {
                dispatch(disablingUserSuccess(data.user));
            })
            .catch(err => {
                dispatch(disablingUserFailure(err))
            });
    }
};

function enablingUser() { return { type: ENABLING_USER } }
function enablingUserSuccess(user) { return { type: ENABLING_USER_SUCCESS, user: user } }
function enablingUserFailure(err) { return { type: ENABLING_USER_FAILURE, error: err } }
const enableAction = (user) => {
    return (dispatch) => {
        dispatch(enablingUser());
        Users.enable(user.id)
            .then(data => {
                dispatch(enablingUserSuccess(data.user));
            })
            .catch(err => {
                dispatch(enablingUserFailure(err))
            });
    }
};

/* Export reducer */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
    return applyReducers(state, action, [ createReducer, startCreateReducer, getReducer, listReducer, updateReducer, enableReducer, disableReducer ]);
}

/* Export CRUD actions */
export const createUser      = createAction;
export const startCreateUser = startCreateAction;
export const loadUser        = getAction;
export const loadUsers       = listAction;
export const updateUser      = updateAction;
export const disableUser     = disableAction;
export const enableUser      = enableAction;
