import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, ButtonGroup, Button, FormGroup, InputGroup } from 'react-bootstrap';
import { Form, Field } from 'react-final-form'
import {
    loadDirectories, setActiveDirectory, setCurrentPaths,
    loadProjects, createAndOpenProject, createDirectory, openProject
} from '@cartier-studio/redux';
import { Subtitle } from '../../typo';
import ReactList from 'react-list';
import Icon from '@mdi/react';
import {
    mdiChevronRight,
    mdiFile,
    mdiFolder,
    mdiLoginVariant,
    mdiHome,
    mdiLoading,
    mdiMagnify,
    mdiPlus,
    mdiUpdate
} from '@mdi/js';
import _ from 'lodash';

import './ProjectsExplorer.scss';

const ProjectsExplorer = ({inSidebar}) => {
    const [search, setSearch]                           = useState('');
    const [filteredProjects, setFilteredProjects]       = useState([]);
    const [filteredDirectories, setFilteredDirectories] = useState([]);

    const { projects, activeDirectory, currentPaths, isLoading, isLoadingOne } = useSelector(state => state.projects);
    const { directories } = useSelector(state => state.directories);

    const { t } = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadDirectories());
        dispatch(loadProjects());
    }, [dispatch]);

    useEffect(() => {
        setFilteredProjects(_.sortBy((projects || []).filter(project => {
            if (search.length !== 0)
                return project.name.match(new RegExp(search, 'gi'))?.length > 0;

            return project.directory == activeDirectory?._id;
        }), p => p.name));
        setFilteredDirectories(_.sortBy((directories || []).filter(directory => {
            if (search.length !== 0)
                return directory.name.match(new RegExp(search, 'gi'))?.length > 0;

            return  directory.parent == activeDirectory?._id;
        }), d => d.name));
    }, [projects, directories?.length, activeDirectory, search]);

    const openDirectory = directory => {
        dispatch(setCurrentPaths(currentPaths.concat([directory])));
        dispatch(setActiveDirectory(directory));
    }

    const goToRootDirectory = () => {
        dispatch(setCurrentPaths([]));
        dispatch(setActiveDirectory(null));
    }

    const goToDirectory = directory => {
        let found = false;
        const newParts = [];
        (currentPaths || []).map(dir => {
            if (found)
                return;
            newParts.push(dir);
            found = found || (directory._id === dir._id);
        });
        dispatch(setCurrentPaths(newParts));
        dispatch(setActiveDirectory(directory));
    }

    const openExistingProject = project => {
        dispatch(openProject(project));
    };

    const createNewProject = values => {
        dispatch(createAndOpenProject({
            ...values,
            directory: activeDirectory?._id
        }));
    };

    const createNewDirectory = values => {
        dispatch(createDirectory({
            ...values,
            parent: activeDirectory?._id
        }));
    };

    return (
        <div className="ProjectsExplorer" size="lg">
            <div className="wrapper">

                <div className="topBar">
                    <Row>
                        <Col className="mb-2" sm={12} md={inSidebar ? 12 : 6}>
                            <Subtitle>{ t('projects.my_projects') }</Subtitle>
                        </Col>
                        <Col sm={12} md={inSidebar ? 12 : 6}>
                            <Form onSubmit={ (form) => setSearch(form.query) } render={ ({ handleSubmit }) => (
                                <form className="search-form" onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <InputGroup className="mb-3">
                                            <Field
                                                component="input"
                                                className="form-control"
                                                placeholder={ t('projects.search') }
                                                name="query"
                                                autoFocus
                                                onKeyUp={ (e) => { setSearch(e.target.value) }}
                                            />
                                            <InputGroup.Text><Icon path={mdiMagnify} size={ 1 }/></InputGroup.Text>
                                        </InputGroup>
                                    </FormGroup>
                                </form>
                            )} />
                        </Col>
                    </Row>
                </div>

                <div className="navigator">
                    <div className="part" onClick={() => goToRootDirectory()}>
                        <div className="name">
                            <Icon path={ mdiHome } size={ 1 }/>
                        </div>
                    </div>
                    {currentPaths.map(directory => (
                        <div key={directory._id} className="part" onClick={() => goToDirectory(directory)}>
                            <div className="separator">/</div>
                            <div className="name">{ directory.name }</div>
                        </div>
                    ))}
                </div>

                <div className="actions">
                    <Row>
                        <Col className="mb-2" sm={12} md={inSidebar ? 12 : 6}>
                            <Form onSubmit={ createNewProject } className="create-form" render={ ({ handleSubmit }) => (
                                <form className="create-form" onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <InputGroup className="mb-3">
                                            <Field component="input" className="form-control" placeholder={ t('projects.new_project_name') } name="name" id="name" required />
                                            <Button type="submit" color="dark" className="btn-block" disabled={ isLoadingOne }>
                                                { !isLoadingOne && <Icon path={ mdiFile } size={ 1 } color="white" /> }
                                                { isLoadingOne && <Icon path={ mdiLoading } size={ 1 } spin={ 1 } color="white" /> }
                                                &nbsp;{ t('projects.create') }
                                            </Button>
                                        </InputGroup>
                                    </FormGroup>
                                </form>
                            )} />
                        </Col>
                        <Col className="mb-2" sm={12} md={inSidebar ? 12 : 6}>
                            <Form onSubmit={ createNewDirectory } className="create-form" render={ ({ handleSubmit }) => (
                                <form className="create-form" onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <InputGroup className="mb-3">
                                            <Field component="input" className="form-control" placeholder={ t('projects.new_directory_name') } name="name" id="name" required />
                                            <Button type="submit" color="dark" className="btn-block" disabled={ isLoadingOne }>
                                                { !isLoadingOne && <Icon path={ mdiFolder } size={ 1 } color="white" /> }
                                                { isLoadingOne && <Icon path={ mdiLoading } size={ 1 } spin={ 1 } color="white" /> }
                                                &nbsp;{ t('projects.create') }
                                            </Button>
                                        </InputGroup>
                                    </FormGroup>
                                </form>
                            )} />
                        </Col>
                    </Row>
                </div>

                { !isLoading && (
                <div className="files">
                    { !inSidebar && (
                    <div className="header">
                        <div className="tr">
                            <div className="th">{ t('projects.explorer.name') }</div>
                                <div className="th">{ t('projects.explorer.version') }</div>
                                <div className="th">{ t('projects.explorer.date') }</div>
                        </div>
                    </div>
                    )}

                    {filteredDirectories && filteredDirectories.map(directory => (
                        <div key={ directory._id } className="tr directory">
                            <div className="td">
                                <div className="item" onDoubleClick={() => openDirectory(directory)}>
                                    <Icon path={ mdiFolder } size={ 1 } color={ '#F8D775' } className="icon"/>
                                    { directory.name }
                                </div>
                            </div>
                            { !inSidebar && (
                                <>
                                    <div className="td text-center">{ "-" }</div>
                                    <div className="td text-center">{ "-" }</div>
                                </>
                            )}
                        </div>
                    ))}

                    { filteredProjects && filteredProjects.map(project => (
                        <div key={ project._id } className="tr project">
                            <div className="td">
                                <div className="item" onDoubleClick={() => openExistingProject(project)}>
                                    <Icon path={ mdiFile } size={ 1 } color={ '#821717' } className="icon"/>
                                    { project.name }
                                </div>
                            </div>
                            { !inSidebar && (
                                <>
                                    <div className="td text-center">V{ project.version }</div>
                                    <div className="td text-center">
                                        { project.updatedAt.format('LLL') }
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
                )}

                { isLoading && (
                    <div className="text-center">
                        <Icon path={ mdiLoading } size={ 3 } spin={ 1 } />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProjectsExplorer;
