import RestClient from './RestClient';
import { Project } from '@cartier-studio/models';

class Projects extends RestClient {
    constructor() {
        super({
            // rest config
            model: Project,
            entryPoint: 'projects',
            sortBy: 'name',
            id_field: '_id',
            // redux config
            resource: 'project',
            resources: 'projects',
        });
    }

    exportTo(id, format) {
        return this.GET(`/projects/${id}/export/${format}`)
            .then(response => response.json());
    }
}

const ProjectsApi = new Projects();

export default ProjectsApi;
