/**
 * Redux Middleware to maintain the Project state
 */
import Project from '../Project';

const romanProjectMiddleware = selector => store => next => action => {
    const result = next(action);
    if (action.meta && action.meta.syncRomanProject) {
        Project.setup(selector(store.getState()));
    }
    return result;
};

export default romanProjectMiddleware;
