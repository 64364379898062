import React from 'react';
import Project from './Project';
import Opening from './Opening';
import Path from './Path';

/**
 * Class representing a round opening
 */
class CircleOpening extends Opening {
    applyScale(scale: Number) {
        this.radius *= scale;
    }

    getPathData() {
        return Path.circlePath(
            this.center.x,
            this.center.y,
            this.radius
        ).pathData;
    }

    getMarker1Path(offset) {
        const project = Project.getInstance();
        return Path.circlePath(this.center.x, this.center.y, this.radius - project.numeralArea?.margin - project.numeralArea?.height - offset)
            .reorient(/*nonZero*/false, /*clockwise*/false);
    }

    getMarker2Path(offset) {
        const project = Project.getInstance();
        return Path.circlePath(
            this.center.x,
            this.center.y,
            this.radius - project.numeralArea?.margin - project.numeralArea?.height + project.numerals?.common.serifHeight - offset
        ).reorient(/*nonZero*/false, /*clockwise*/true);
    }

    getMarker3Path(offset) {
        const project = Project.getInstance();
        return Path.circlePath(
            this.center.x,
            this.center.y,
            this.radius - project.numeralArea?.margin - project.numerals?.common.serifHeight - offset
        ).reorient(/*nonZero*/false, /*clockwise*/false);
    }

    getMarker4Path(offset) {
        const project = Project.getInstance();
        return Path.circlePath(this.center.x, this.center.y, this.radius - (project?.numeralArea?.margin || 0) - offset)
            .reorient(/*nonZero*/false, /*clockwise*/true);
    }
}

export default CircleOpening;
