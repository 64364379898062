import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiLoading } from '@mdi/js';

import './FullPageLoader.scss';

const FullPageLoader = ({ absolute }) => {
    return (
        <Container className={`${absolute ? 'absolute' : ''} FullPageLoader vh-100` }>
            <Row className="vh-100 justify-content-center">
                <Col className="align-self-center spinner-wrapper">
                    <Icon path={mdiLoading} color="#991B1B" spin={1} />
                </Col>
            </Row>
        </Container>
    );
}

export default FullPageLoader;
