import { Auth } from '@cartier-studio/services';
import { User } from '@cartier-studio/models';

const LOG_IN             = 'cartier-studio/auth/LOG_IN';
const LOG_IN_SUCCESS     = 'cartier-studio/auth/LOG_IN_SUCCESS';
const LOG_IN_FAILURE     = 'cartier-studio/auth/LOG_IN_FAILURE';
const LOG_OUT            = 'cartier-studio/auth/LOG_OUT';
const START_CHECKING_JWT = 'cartier-studio/auth/START_CHECKING_JWT';
const END_CHECKING_JWT   = 'cartier-studio/auth/END_CHECKING_JWT';

const initialState = {
    user: {},
    isAuthenticating: false,
    isLoggingIn: false,
    logInError: false,
    logInErrorMessage: '',
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOG_IN:
            return {
                ...state,
                isLoggingIn: true,
                logInError: false
            };
        case LOG_IN_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                user: action.user
            };
        case LOG_IN_FAILURE:
            return {
                ...state,
                isLoggingIn: false,
                logInError: true,
                logInErrorMessage: action.error.message
            };
        case START_CHECKING_JWT:
            return {
                ...state,
                isAuthenticating: true
            };
        case END_CHECKING_JWT:
            return {
                ...state,
                isAuthenticating: false
            };
        case LOG_OUT:
            return {
                ...initialState,
            };
        default:
            return state;
    };
};

function logIn() { return { type: LOG_IN } }
function logOut() { return { type: LOG_OUT } }
function logInSuccess(user) { return { type: LOG_IN_SUCCESS, user: user } }
function logInFailure(err) { return { type: LOG_IN_FAILURE, error: err } }

function startCheckingJwt() { return { type: START_CHECKING_JWT } }
function endCheckingJwt() { return { type: END_CHECKING_JWT } }
export function getLastConnectedUser() {
    return (dispatch) => {
        dispatch(startCheckingJwt());
        let user = JSON.parse(localStorage.getItem('user'));
        if (!user)
            return dispatch(endCheckingJwt());
        Auth.checkJwt(user.token).then(isValid => {
            if (isValid) {
                dispatch(logInSuccess(new User(user)));
                dispatch(endCheckingJwt());
            } else {
                dispatch(endCheckingJwt());
            }
        });
    }
}

export function authenticate(username, password) {
    return (dispatch) => {
        dispatch(logIn());
        Auth.logIn(username, password).then(data => {
            const user = data.user;
            dispatch(logInSuccess(new User(user)));
        }).catch(err => {
            localStorage.setItem('user', null);
            dispatch(logInFailure(new Error('Identifiant ou mot de passe invalide')))
        });
    }
}

export function logout() {
    return (dispatch) => {
        localStorage.setItem('user', null);
        dispatch(logOut());
    }
}
