import { useDispatch } from 'react-redux';
import { updateViewOptions } from '@cartier-studio/redux';
import { Slider } from '../Slider';
import Icon from '@mdi/react';
import {
    mdiRotateLeft,
    mdiRotateRight,
} from '@mdi/js';
import './style.scss';

const RotationSlider = ({ project }) => {
    const dispatch = useDispatch();
    const onChange = (e) => {
        const rotation = e.target.value;
        dispatch(updateViewOptions({ rotation }));
    }

    if (!project || !project.viewOptions)
        return null;

    const { rotation } = project.viewOptions;

    return (
        <div className="RotationSlider">
            <Icon path={ mdiRotateLeft } />
            <Slider
                min="0"
                max="360"
                step="30"
                value={ rotation }
                onChange={ onChange }
            />
            <Icon path={ mdiRotateRight } />
        </div>
    );
};

export default RotationSlider;
