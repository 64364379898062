import paper from 'paper';

/**
 * Class representing a 2D point.
 *
 * see http://paperjs.org/reference/point
 */
class Point extends paper.Point {
    translate(dx, dy): Point {
        return new Point(this.x + dx, this.y + dy);
    }

    distanceTo(otherPoint: Point) {
        return  Math.sqrt(Math.pow(this.x - otherPoint.x, 2) + Math.pow(this.y - otherPoint.y, 2));
    }

    middleDistanceTo(otherPoint: Point) {
        return new Point(
            (this.x + otherPoint.x) / 2,
            (this.y + otherPoint.y) / 2,
        );
    }
}

export default Point;
