import { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiLinkVariant, mdiLinkVariantOff } from '@mdi/js';
import _ from 'lodash';
import './style.scss';

const LinkedInputs = ({ labels, names, defaultValues, onChange, type, step }) => {
    const [values, setValues] = useState(defaultValues);
    const [linked, setLinked] = useState(false);
    const toggleLinked = (e) => {
        setLinked(!linked);
    }

    const onInputChange = (e, index) => {
        const diff = e.target.value - values[index];

        const newValues = _.clone(values);
        names.map((name, i) => {
            if (i !== index && !linked)
                return;

            newValues[i] = Number(Number.parseFloat(values[i] + diff).toFixed(2));
        });
        setValues(newValues);
        onChange(newValues.map((value, i) => ({
            name: names[i],
            value
        })));
    }

    return (
        <div className="LinkedInputs">
            <div className="labels-wrapper">
                <div className="option-label">{ labels[0] }</div>
                <div className="option-label">{ labels[1] }</div>
            </div>
            <div className="inputs-wrapper">
                <div className="option-input">
                    <Form.Control
                        type={type}
                        step={ step }
                        name={names[0]}
                        value={ values[0] }
                        onChange={ e => onInputChange(e, 0) }
                    />
                </div>
                <div className="button-wrapper">
                    <Button variant={linked ? 'primary' : 'dark'} size="sm" onClick={ toggleLinked }>
                        <Icon color='#FFFFFF' variant="primary" size={ 1 } path={ linked ? mdiLinkVariant : mdiLinkVariantOff } />
                    </Button>
                </div>
                <div className="option-input">
                    <Form.Control
                        disabled={linked}
                        type={type}
                        step={ step }
                        name={names[1]}
                        value={ values[1] }
                        onChange={ e => onInputChange(e, 1) }
                    />
                </div>
            </div>
        </div>
    );
};

export default LinkedInputs;
