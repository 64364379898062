import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { romanProjectMiddleware } from '@cartier-studio/romans-lib';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import thunkMiddleware from 'redux-thunk';
import PersistTransformer from './persistTransformer';

import reducer from './ducks/reducer';

const persistConfig = {
    key: 'app',
    storage,
    whitelist: ['editor'],
    transforms: [ PersistTransformer ],
    stateReconciler: autoMergeLevel1
};
const persistedReducer = persistReducer(persistConfig, reducer);

const configureStore = (initialState)  => {
    const middlewares        = [thunkMiddleware, romanProjectMiddleware(state => state.editor.project)];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers         = [middlewareEnhancer];
    const composedEnhancers = compose(...enhancers);

    const store = createStore(persistedReducer, initialState, composedEnhancers);

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./ducks/reducer', () => store.replaceReducer(reducer));
    }

    let persistor = persistStore(store);
    return { store, persistor };
}

const { store, persistor } = configureStore();
export {
    store,
    persistor
};
export * from './ducks';
