import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, Button, FormGroup, ListGroup, Form as BSForm } from 'react-bootstrap';
import { Form, Field } from 'react-final-form'
import { saveProject, duplicateAndOpenProject, closeAndDeleteProject } from '@cartier-studio/redux';
import { Subtitle } from '../../typo';
import { Confirm } from '../../misc';
import Icon from '@mdi/react';
import {
    mdiContentSave,
    mdiContentCopy,
    mdiDelete,
    mdiPencil,
    mdiLoading,
    mdiArrowLeft,
} from '@mdi/js';

import './ProjectDetails.scss';

const ProjectDetails = () => {
    const [activeMode, setActiveMode]   = useState('view');
    const [showConfirm, setShowConfirm] = useState(false);

    const { project, isLoadingOne } = useSelector(state => state.projects);
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const save = values => {
        dispatch(saveProject(values));
    };

    const duplicate = e => {
        dispatch(duplicateAndOpenProject(project));
        setActiveMode('edit');
    };

    const destroy = e => {
        dispatch(closeAndDeleteProject(project));
        setShowConfirm(false);
    };

    if (!project)
        return null;

    return (
        <div className="ProjectDetails">
            { activeMode === 'view' && (
                <div className="details">
                    <Subtitle>{ project.name }</Subtitle>
                    <ListGroup as="ol">
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    { t('projects.created_on') }
                                </div>
                                { project.createdAt.format('LLL') }
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    { t('projects.last_update_on') }
                                </div>
                                { project.updatedAt.format('LLL') }
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    { t('projects.version') }
                                </div>
                                { project.version }
                            </div>
                        </ListGroup.Item>
                    </ListGroup>
                    <Subtitle>{ t('projects.description') }</Subtitle>
                    <div className="description">
                        { project.description || t('projects.no_description_yet') }
                    </div>
                    <Subtitle>{ t('projects.comments') }</Subtitle>
                    <div className="comments">
                        { project.comments || t('projects.no_comments_yet') }
                    </div>
                    <FormGroup>
                        <Button onClick={ () => setActiveMode('edit') }>
                            <Icon path={ mdiPencil } size={ 1 } color="white" />
                            &nbsp;{ t('projects.edit') }
                        </Button>
                    </FormGroup>
                    <FormGroup className="mt-5">
                        <Button variant="dark" size="sm" onClick={ duplicate }>
                            <Icon path={ mdiContentCopy } size={ 1 } color="white" />
                            &nbsp;{ t('projects.duplicate') }
                        </Button>
                    </FormGroup>
                    <FormGroup>
                    </FormGroup>
                </div>
            )}

            { activeMode === 'edit' && (
            <div className="edit-project-wrapper">
                <Subtitle>{ t('projects.project_edition') }</Subtitle>
                <Form onSubmit={ save } initialValues={ project } className="edit-form" render={ ({ handleSubmit }) => (
                    <form className="edit-form" onSubmit={handleSubmit}>
                        <FormGroup className="form-row">
                            <BSForm.Label>{ t('projects.name') }</BSForm.Label>
                            <Field component="input" className="form-control" name="name" id="name" required />
                        </FormGroup>
                        <FormGroup className="form-row">
                            <BSForm.Label>{ t('projects.version') }</BSForm.Label>
                            <Field component="input" type="number" className="form-control" name="version" id="version" required />
                        </FormGroup>
                        <FormGroup className="form-row">
                            <BSForm.Label>{ t('projects.description') }</BSForm.Label>
                            <Field component="textarea" className="form-control" name="description" id="description" rows={ 6 } />
                        </FormGroup>
                        <FormGroup className="form-row">
                            <BSForm.Label>{ t('projects.comments') }</BSForm.Label>
                            <Field component="textarea" className="form-control" name="comments" id="comments" rows={ 6 }/>
                        </FormGroup>
                        <FormGroup className="form-row">
                            <ButtonGroup>
                                <Button  onClick={ () => { setActiveMode('view') }} variant="dark">
                                    <Icon path={ mdiArrowLeft } size={ 1 } color="white" />
                                    &nbsp;{ t('projects.back') }
                                </Button>
                                <Button type="submit">
                                    { isLoadingOne && (<Icon path={ mdiLoading } spin size={ 1 } />) }
                                    { !isLoadingOne && (<Icon path={ mdiContentSave } size={ 1 } />) }
                                    &nbsp;{ t('projects.save') }
                                </Button>
                            </ButtonGroup>
                        </FormGroup>
                        <FormGroup className="mt-5">
                                <Button variant="danger" onClick={ () => setShowConfirm(true) } size="sm">
                                    <Icon path={ mdiDelete } size={ .75 } color="white" />
                                    &nbsp;{ t('projects.delete') }
                                </Button>
                        </FormGroup>
                    </form>
                )} />
            </div>
            )}
            <Confirm
                visible={ showConfirm }
                onHide={ () => setShowConfirm(false) }
                onConfirm={ destroy }
                title={ t('projects.delete_modal_title') }
                question={ t('projects.delete_modal_question') }
                text={ t('projects.delete_modal_text') }
            />
        </div>
    );
}

export default ProjectDetails;
