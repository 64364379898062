import { Slider } from '../Slider';
import Icon from '@mdi/react';
import {
    mdiImagePlusOutline,
    mdiImageMinusOutline,
} from '@mdi/js';
import './style.scss';

const BackgroundImageScaler = ({ project, onChange }) => {
    if (!project)
        return null;

    const background = project.opening?.background || {};
    if (!background.rfc2397_data)
        return null;

    return (
        <div className="BackgroundImageScaler">
            <Icon path={ mdiImageMinusOutline } />
            <Slider
                min={ 0.4 }
                max={ 2 }
                step={ 0.001 }
                value={ background.scale }
                onChange={ e => onChange(e.target.value) }
            />
            <Icon path={ mdiImagePlusOutline } />
        </div>
    );
};

export default BackgroundImageScaler;
