import React from 'react';
import Project from './Project';
import Opening from './Opening';
import Path from './Path';

/**
 * Class representing a square opening
 */
class SquareOpening extends Opening {
    applyScale(scale: Number) {
        this.width  *= scale;
        this.height *= scale;
    }

    getPath() {
        return Path.squarePathWithSize(
            this.center.x - (this.width / 2),
            this.center.y - (this.height / 2),
            this.width, this.height, this.radius
        );
    }

    getPathData() {
        const { pathData } = this.getPath();
        return  pathData;
    }

    getMarker1Path(offset) {
        const project = Project.getInstance();
        const space   = project.numeralArea.margin + project.numeralArea.height + offset;
        const scaleXRatio = 1 - (space / this.width);
        const scaleYRatio = this.scaleType === 'offset' ? 1 - (space / this.height) : scaleXRatio;
        const mainPath = this.getPath();
        return mainPath.scale(scaleXRatio, scaleYRatio);
    }

    getMarker2Path(offset) {
        const project = Project.getInstance();
        const space   = project.numeralArea.margin + project.numeralArea.height - project.numerals.common.serifHeight + offset;
        const scaleXRatio = 1 - (space / this.width);
        const scaleYRatio = 1 - (space / this.height);
        const mainPath = this.getPath();
        return mainPath.scale(scaleXRatio, scaleYRatio);
    }

    getMarker3Path(offset) {
        const project = Project.getInstance();
        const space   = project.numeralArea.margin + project.numerals.common.serifHeight + offset;
        const scaleXRatio = 1 - (space / this.width);
        const scaleYRatio = 1 - (space / this.height);
        const mainPath = this.getPath();
        return mainPath.scale(scaleXRatio, scaleYRatio);
    }

    getMarker4Path(offset) {
        const project = Project.getInstance();
        const space   = project.numeralArea.margin + offset;
        const scaleXRatio = 1 - (space / this.width);
        const scaleYRatio = 1 - (space / this.height);
        const mainPath = this.getPath();
        return mainPath.scale(scaleXRatio, scaleYRatio);
    }
}

export default SquareOpening;
