import Roman from './Roman';
import Project from './Project';
import Segment from './Segment';
import Logo from './Logo';

/**
 * Class representing a roman 7 number
 *
 *           G________H     N___________________________O
 *           |        |     |                           |
 *           F__E  J__I     M__L C____X   a____b   Q____P
 *              \   \          //     |   |    |   |
 *               \   \        e f     |   |    |   |
 *                \   \     g h       |   |    |   |
 *                 \   \    //        |   |    |   |
 *                  \   \ K//         |   |    |   |
 *                   \   \//     V ___W   |d___c   R____S
 *                    \   /      |                      |
 *                     \ /       U______________________T
 *                      D
 *                     / \
 *                    /   \
 *                   /     \
 *                  Y       Z
 */
class RomanSeven extends Roman {
    constructor() {
        super(/*index*/7);
    }

    buildSegments() {
        const project = Project.getInstance();
        if (!project.numerals)
            return;

        const { signature }                            = project;
        const { serifWidth, barsWidth, thinBarsWidth } = project.numerals.common;
        const {
            fullTopWidth, fullBottomWidth,
            bottomOffset, topBarsDst,
            spaceWidth, offset, followOffsetAxis
        } = project.numerals[this.positionIndex];

        const radialOffset = followOffsetAxis ? 0 : offset / 2.6;

        // shortcut
        const s = (marker, first, last, yOffset, data) => Segment.onOpening(7, marker, first, last, /*xOffset*/0, yOffset, /*radialOffset*/0, data);

        const O = fullTopWidth / 2 + serifWidth + radialOffset;
        const G = -1 * O + (2 * radialOffset);
        const F = G;
        const E = F + serifWidth;
        const H = G + 2 * serifWidth + barsWidth;
        const I = H;
        const J = I - serifWidth;

        const N = H + topBarsDst;
        const M = N;
        const L = M + serifWidth;
        const C = L + thinBarsWidth;
        const P = O;
        const Q = P - serifWidth;
        const R = Q;
        const S = R + serifWidth;
        const T = S;
        const U = T - 2 * barsWidth - spaceWidth - 2 * serifWidth;
        const V = U;
        const W = V + serifWidth;
        const X = W;

        const a = X + barsWidth;
        const b = a + spaceWidth;
        const d = a;
        const c = b;

        const DPos = T - fullBottomWidth;
        const D    = s(1, DPos, DPos, bottomOffset, {startLabel: 'D'});

        // Five
        const EF = s(3, E, F, 0, {startLabel: 'E', endLabel: 'F'});
        const GH = s(4, G, H, 0, {startLabel: 'G', endLabel: 'H'});
        const IJ = s(3, I, J, 0, {startLabel: 'I', endLabel: 'J'});
        const LM = s(3, L, M, 0, {startLabel: 'L', endLabel: 'M'});
        const NO = s(4, N, O, 0, {startLabel: 'N', endLabel: 'O'});

        // One
        const PQ = s(3, P, Q, 0, {startLabel: 'P', endLabel: 'Q'});
        const RS = s(2, R, S, 0, {startLabel: 'R', endLabel: 'S'});
        const TU = s(1, T, U, 0, {startLabel: 'T', endLabel: 'U'});
        const VW = s(2, V, W, 0, {startLabel: 'V', endLabel: 'W'});
        const XC = s(3, X, C, 0, {startLabel: 'X', endLabel: 'c'});

        const ba = s(3, b, a, 0, {startLabel: 'b', endLabel: 'a'});
        const dc = s(2, d, c, 0, {startLabel: 'd', endLabel: 'c'});

        const YZ = s(1, DPos - thinBarsWidth, DPos + barsWidth, bottomOffset, {startLabel: 'Y', endLabel: 'Z'});

        const Y = YZ.start;
        const Z = YZ.end;
        const JZ = Segment.betweenPoints(IJ.end, Z, 0, {startLabel: 'J', endLabel: 'Z'});
        const LY = Segment.betweenPoints(LM.start, Y, 0, {startLabel: 'L', endLabel: 'Y'});

        const intersection = LY.getIntersectionWith(JZ);

        const K = Segment.betweenPoints(intersection, intersection);

        this.boundingBoxCorners = [ GH.start, NO.end, TU.start, D.start ];

        // no signature
        if (signature.onNumeral !== 7)
            return this.segments = [
                [
                    D,
                    EF,
                    GH,
                    IJ,
                    K,
                    LM,
                    NO,
                    PQ,
                    RS,
                    TU,
                    VW,
                    XC,
                ],
                [
                    ba,
                    dc
                ]
            ];

        // signature
        const CD = Segment.betweenPoints(XC.end, D.start);
        const LK = Segment.betweenPoints(LM.start, K.start);

        const options = signature[signature.onNumeral];
        const e = LK.getPoint(-1 * options.thinBarLength1);
        const f = CD.getPoint(-1 * options.thinBarLength2);
        const h = CD.getPoint(options.thinBarLength3);
        const g = LK.getPoint(options.thinBarLength4);
        const fe = Segment.betweenPoints(f, e);
        const gh = Segment.betweenPoints(g, h);
        this.segments = [
            [
                D,
                EF,
                GH,
                IJ,
                K,
                gh,
            ],
            [
                LM,
                NO,
                PQ,
                RS,
                TU,
                VW,
                XC,
                fe,
            ],
            [
                ba,
                dc
            ]
        ];
    }

    getJsxExtraPaths() {
        const project = Project.getInstance();
        if (!project.signature)
            return null;

        return project.signature.onNumeral === 7 ? (new Logo()).toJsx() : null;
    }

    getSvgExtraPaths() {
        const project = Project.getInstance();
        if (!project.signature)
            return null;

        return project.signature.onNumeral === 7 ? (new Logo()).toSvg() : null;
    }
}

export default RomanSeven;
