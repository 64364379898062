import { Project as RomanProject }  from '@cartier-studio/romans-lib';
import _ from 'lodash';
import { v4 } from 'uuid';

/**
 * Class representing a project
 */
class Project extends RomanProject {
    /**
     * Get the fields to get server side when we
     * ask for a listing
     */
    static getListingFields() {
        return [];
    }

    prepareForDuplicate() {
        const prepared = _.cloneDeep(this);

        prepared._id   = v4();
        prepared.name += ' - Copy';

        return prepared;
    }
}

export default Project;
