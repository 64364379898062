import { createProject, loadProject, updateProject, deleteProject, duplicateProject } from './projects';
import { Project } from '../../models';
import _ from 'lodash';

/* Const */
const OPEN_PROJECT           = 'cartier-studio/editor/OPEN_PROJECT';
const OPEN_PROJECT_SUCCESS   = 'cartier-studio/editor/OPEN_PROJECT_SUCCESS';
const OPEN_PROJECT_FAILED    = 'cartier-studio/editor/OPEN_PROJECT_FAILED';
const CLOSE_PROJECT          = 'cartier-studio/editor/CLOSE_PROJECT';
const SAVE_PROJECT           = 'cartier-studio/editor/SAVE_PROJECT';
const SAVE_PROJECT_SUCCESS   = 'cartier-studio/editor/SAVE_PROJECT_SUCCESS';
const SAVE_PROJECT_FAILED    = 'cartier-studio/editor/SAVE_PROJECT_FAILED';
const UPDATE_VIEW_OPTIONS    = 'cartier-studio/editor/UPDATE_VIEW_OPTIONS';
const UPDATE_OPENING         = 'cartier-studio/editor/UPDATE_OPENING';
const UPDATE_NUMERAL_AREA    = 'cartier-studio/editor/UPDATE_NUMERAL_AREA';
const UPDATE_NUMERALS        = 'cartier-studio/editor/UPDATE_NUMERALS';
const UPDATE_NUMERALS_COMMON = 'cartier-studio/editor/UPDATE_NUMERALS_COMMON';
const UPDATE_LAYERS          = 'cartier-studio/editor/UPDATE_LAYERS';
const UPDATE_SIGNATURE       = 'cartier-studio/editor/UPDATE_SIGNATURE';
const UPDATE_VIEWER          = 'cartier-studio/editor/UPDATE_VIEWER';
const SET_IS_EXPORTING       = 'cartier-studio/editor/SET_IS_EXPORTING';

const initialState = {
    isOpeningProject: false,
    isSavingProject: false,
    isExporting: false,
    project: null,
    projectCanBeSaved: false,
    editorError: null,
    viewer: {
        width: 0,
        height: 0
    }
};

/* Export reducer */
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_PROJECT:
            return {
                ...state,
                isOpeningProject: true,
                editorError: null,
            };
        case OPEN_PROJECT_SUCCESS:
            return {
                ...state,
                project: action.project,
                projectCanBeSaved: false,
                isOpeningProject: false,
                editorError: null,
            };
        case OPEN_PROJECT_FAILED:
            return {
                ...state,
                project: null,
                projectCanBeSaved: false,
                isOpeningProject: false,
                editorError: action.Error,
            };
        case CLOSE_PROJECT:
            return {
                ...state,
                project: null,
                projectCanBeSaved: false,
            };
        case SAVE_PROJECT:
            return {
                ...state,
                isSavingProject: true,
                editorError: null,
            };
        case SAVE_PROJECT_SUCCESS:
            return {
                ...state,
                isSavingProject: false,
                project: action.project,
                projectCanBeSaved: false,
                editorError: null,
            };
        case SAVE_PROJECT_FAILED:
            return {
                ...state,
                isSavingProject: false,
                projectCanBeSaved: false,
                editorError: action.error,
            };
        case UPDATE_VIEW_OPTIONS:
            return {
                ...state,
                project: {
                    ...state.project,
                    viewOptions: {
                        ...state.project.viewOptions,
                        ...action.viewOptions
                    }
                },
            };
        case UPDATE_OPENING:
            const properties = _.cloneDeep(state.project);
            _.assign(properties?.opening, action.opening);
            const newProject = new Project(properties);

            return {
                ...state,
                project: newProject,
                projectCanBeSaved: true,
            };
        case UPDATE_NUMERAL_AREA:
            return {
                ...state,
                project: {
                    ...state.project,
                    numeralArea: {
                        ...state.project.numeralArea,
                        ...action.numeralArea,
                    }
                },
                projectCanBeSaved: true,
            };
        case UPDATE_NUMERALS:
            return {
                ...state,
                project: {
                    ...state.project,
                    numerals: {
                        ...state.project.numerals,
                        [action.index]: {
                            ...state.project.numerals[action.index],
                            ...action.numerals,
                        }
                    }
                },
                projectCanBeSaved: true,
            };
        case UPDATE_NUMERALS_COMMON:
            return {
                ...state,
                project: {
                    ...state.project,
                    numerals: {
                        ...state.project.numerals,
                        common: {
                            ...state.project.numerals.common,
                            ...action.common
                        },
                    }
                },
                projectCanBeSaved: true,
            };
        case UPDATE_LAYERS:
            return {
                ...state,
                project: {
                    ...state.project,
                    layers: {
                        ...state.project.layers,
                        ...action.layers,
                    }
                },
                projectCanBeSaved: ('active' in action.layers ? state.projectCanBeSaved : true),
            };
        case UPDATE_SIGNATURE:
            return {
                ...state,
                project: {
                    ...state.project,
                    signature: {
                        ...state.project.signature,
                        ...action.signature,
                    }
                },
                projectCanBeSaved: true,
            };
        case UPDATE_VIEWER:
            return {
                ...state,
                viewer: {
                    ...state.viewer,
                    ...action.viewer,
                },
            };
        case SET_IS_EXPORTING:
            return {
                ...state,
                isExporting: action.isExporting
            };
        default:
            return state;
    }
}

const syncProjectAction = (type, args) => dispatch => { dispatch({ type, ...args, meta: { syncRomanProject: true }}); };
/* Actions */
export const updateViewOptions = (viewOptions) => syncProjectAction(UPDATE_VIEW_OPTIONS, { viewOptions });
export const updateOpening = (opening) => syncProjectAction(UPDATE_OPENING, { opening});
export const updateNumeralArea = (numeralArea) => syncProjectAction(UPDATE_NUMERAL_AREA, { numeralArea });
export const updateNumerals = (index, numerals) => syncProjectAction(UPDATE_NUMERALS, { index, numerals });
export const updateNumeralsCommon = (common) => syncProjectAction(UPDATE_NUMERALS_COMMON, { common });
export const updateLayers = (layers) => syncProjectAction(UPDATE_LAYERS, { layers });
export const updateSignature = (signature) => syncProjectAction(UPDATE_SIGNATURE, { signature });
export const updateViewer = (viewer) => syncProjectAction(UPDATE_VIEWER, { viewer });

export const setIsExporting = (isExporting) => dispatch => { dispatch({ type: SET_IS_EXPORTING, isExporting}); };
export const closeProject = () => dispatch => { dispatch({ type: CLOSE_PROJECT}); };
export const openProject = project => dispatch => {
    dispatch({ type: OPEN_PROJECT });
    dispatch(loadProject(project._id, (err, project) => {
        if (err)
            dispatch({ type: OPEN_PROJECT_FAILED, error: err.message, meta: { syncRomanProject: true } });
        else
            dispatch({ type: OPEN_PROJECT_SUCCESS, project, meta: { syncRomanProject: true } });
    }));
};
export const saveProject = project => dispatch => {
    dispatch({ type: SAVE_PROJECT });
    dispatch(updateProject(project, (err, project) => {
        if (err)
            dispatch({ type: SAVE_PROJECT_FAILED, error: err.message });
        else
            dispatch({ type: SAVE_PROJECT_SUCCESS, project });
    }));
};
export const createAndOpenProject = project => dispatch => {
    dispatch(createProject(project, (err, project) => {
        if (err)
            return;
        dispatch(openProject(project));
    }));
};
export const duplicateAndOpenProject = project => dispatch => {
    dispatch(duplicateProject(project, (err, project) => {
        if (err)
            return;
        dispatch(openProject(project));
    }));
};
export const closeAndDeleteProject = project => dispatch => {
    dispatch(deleteProject(project._id, (err) => {
        if (err)
            return;
        dispatch(closeProject());
    }));
};
