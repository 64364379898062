/**
 * Class representing the numeral area
 */
class NumeralArea {
    constructor(options: Object) {
        this.margin = options.margin;
        this.height = options.height;
        this.center = options.center;
    }
}

export default NumeralArea;
