import React from 'react';
import Path from './Path';

/**
 * Class representing a round opening
 */
class OpeningCenter {

    constructor(properties) {
        Object.assign(this, properties || {});
    }

    toJsx() {
        const { pathData } = Path.circlePath(
            this.x,
            this.y,
            this.radius
        );

        return (
            <path
                fill="none"
                stroke="#000000"
                strokeWidth="0.01"
                className="opening-center"
                d={ pathData }
            />
        );
    }

    toSvg() {
        const { pathData } = Path.circlePath(
            this.x,
            this.y,
            this.radius
        );

        return `<path fill="none" stroke="#000000" stroke-width="0.01" class="opening-center" d="${pathData}" />`;
    }
}

export default OpeningCenter;
