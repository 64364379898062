import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { Title } from '../../../typo';
import { saveProject, closeProject, openProject } from '@cartier-studio/redux';
import Icon from '@mdi/react';
import {
    mdiContentSave,
    mdiClose,
    mdiFileUndo,
    mdiDownload,
    mdiChevronDoubleLeft,
    mdiChevronDoubleRight,
    mdiLoading
} from '@mdi/js';

import './style.scss';

const ProjectInfos = ({ project, showProjectDetails, showExplorer, showExporter }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { isOpeningProject, isSavingProject, projectCanBeSaved } = useSelector(state => state.editor);
    const { project: savedProject } = useSelector(state => state.projects);

    if (!project)
        return null;

    const save   = () => { dispatch(saveProject(project)); }
    const revert = () => { dispatch(openProject(savedProject)); }
    const close  = () => { dispatch(closeProject()); }

    return (
        <div className="ProjectInfos">
            <Row>
                <Col md="auto" className="project-details-button align-items-center d-flex" >
                    <Button variant="outline" onClick={ showProjectDetails } >
                        <Icon path={ mdiChevronDoubleRight } size={ 2 } color="#FFFFFF" />
                    </Button>
                </Col>
                <Col>
                    <Title>
                        { project.name }
                    </Title>
                    <span>
                        { t('projects.version')} { project.version }
                    </span>
                </Col>
                <Col md="auto" className="toolbar align-items-center d-flex">
                    <Button size="sm" variant="warning" disabled={ projectCanBeSaved } onClick={ showExporter } >
                        <Icon path={ mdiDownload } size={ 1 } />
                        {' '}{ t('projects.export') }
                    </Button>
                    <Button size="sm" variant="dark" disabled={ !projectCanBeSaved } onClick={ revert } >
                        { isOpeningProject && (<Icon path={ mdiLoading } spin size={ 1 } />) }
                        { !isOpeningProject && (<Icon path={ mdiFileUndo } size={ 1 } />) }
                        {' '}{ t('projects.revert') }
                    </Button>
                    <Button size="sm" variant="success" disabled={ !projectCanBeSaved } onClick={ save } >
                        { isSavingProject && (<Icon path={ mdiLoading } spin size={ 1 } />) }
                        { !isSavingProject && (<Icon path={ mdiContentSave } size={ 1 } />) }
                        {' '}{ t('projects.save') }
                    </Button>
                    <Button size="sm" variant="primary" onClick={ close } >{' '}
                        <Icon path={ mdiClose } size={ 1 } />
                        { t('projects.close') }
                    </Button>
                </Col>
                <Col md="auto" className="opener-button align-items-center d-flex" >
                    <Button variant="outline" onClick={ showExplorer } >
                        <Icon path={ mdiChevronDoubleLeft } size={ 2 } color="#FFFFFF" />
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default ProjectInfos;
