import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, ButtonGroup, Button, FormGroup, InputGroup } from 'react-bootstrap';
import { Form, Field } from 'react-final-form'
import { loadUsers, createUser, updateUser } from '@cartier-studio/redux';
import { User } from '@cartier-studio/models';
import { Subtitle } from '../../typo';
import ReactList from 'react-list';
import Icon from '@mdi/react';
import {
    mdiArrowLeft,
    mdiAccount,
    mdiAccountPlus,
    mdiContentSave,
    mdiLoginVariant,
    mdiHome,
    mdiLoading,
    mdiMagnify,
    mdiPlus,
    mdiUpdate
} from '@mdi/js';
import _ from 'lodash';

import './UsersList.scss';

const UsersList = ({inSidebar}) => {
    const [search, setSearch]               = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [activeUser, setActiveUser]       = useState(null);

    const { users, isLoading } = useSelector(state => state.users);

    const { t } = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadUsers());
    }, [dispatch]);

    useEffect(() => {
        setFilteredUsers(_.sortBy((users || []).filter(user => {
            if (search.length !== 0)
                return (
                    user.firstname.match(new RegExp(search, 'gi'))?.length > 0 ||
                    user.lastname.match(new RegExp(search, 'gi'))?.length > 0
                );

            return true;
        }), p => p.lastname));
    }, [users, search]);

    const showUser = user => {
        setActiveUser(user);
    };

    const startCreateuser = () => {
        setActiveUser(new User());
    };

    const saveUser = user => {
        if (user?._id) {
            console.log('updating user', user);
            dispatch(updateUser(user));
            setTimeout(() => { dispatch(loadUsers()); }, 1000);
        } else {
            console.log('registering user', user);
            dispatch(createUser(user));
            setActiveUser(null);
            setTimeout(() => { dispatch(loadUsers()); }, 1000);
        }
    };

    console.log('users', users);
    return (
        <div className="UsersList" size="lg">
            <div className="wrapper">

                <div className="topBar">
                    <Row>
                        <Col className="mb-2" sm={12} md={4}>
                            <Subtitle>{ t('users.users') }</Subtitle>
                        </Col>
                        <Col sm={12} md={8}>
                            <Form onSubmit={ (form) => setSearch(form.query) } render={ ({ handleSubmit }) => (
                                <form className="search-form" onSubmit={handleSubmit}>
                                    <Row>
                                        <Col sm={8}>
                                            <FormGroup>
                                                <InputGroup className="mb-3">
                                                    <Field
                                                        component="input"
                                                        className="form-control"
                                                        placeholder={ t('users.search') }
                                                        name="query"
                                                        autoFocus
                                                        onKeyUp={ (e) => { setSearch(e.target.value) }}
                                                    />
                                                    <InputGroup.Text><Icon path={mdiMagnify} size={ 1 }/></InputGroup.Text>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={4}>
                                            <FormGroup className="">
                                                <Button onClick={ () => { startCreateuser() }} variant="dark">
                                                    <Icon path={ mdiAccountPlus } size={ 1 } color="white" />
                                                    &nbsp;{ t('users.create') }
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </form>
                            )} />
                        </Col>
                    </Row>
                </div>

                { !activeUser && !isLoading && (
                <div className="files">
                    { !inSidebar && (
                    <div className="header">
                        <div className="tr">
                            <div className="th">{ t('users.lastname') }</div>
                                <div className="th">{ t('users.firstname') }</div>
                                <div className="th">{ t('users.email') }</div>
                        </div>
                    </div>
                    )}

                    { filteredUsers && filteredUsers.map(user => (
                        <div key={ user._id } className="tr user">
                            <div className="td">
                                <div className="item" onDoubleClick={() => showUser(user)}>
                                    { user.firstname }
                                </div>
                            </div>
                            <div className="td text-center">{ user.lastname }</div>
                            <div className="td text-center">{ user.email }</div>
                        </div>
                    ))}
                </div>
                )}

                {activeUser && (
                <div className="files">
                    <Form onSubmit={ (form) => saveUser(form) } render={ ({ handleSubmit }) => (
                        <form className="user-form" onSubmit={handleSubmit}>
                            {activeUser._id && (
                                <Field component="input" type="hidden" name="_id" defaultValue={ activeUser._id} />
                            )}
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>{ t('users.firstname') }</InputGroup.Text>
                                            <Field
                                                component="input"
                                                className="form-control"
                                                placeholder={ t('users.firstname') }
                                                name="firstname"
                                                defaultValue={ activeUser.firstname}
                                                autoFocus
                                                onKeyUp={ (e) => { setActiveUser({ ...activeUser, firstname: e.target.value}) }}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>{ t('users.lastname') }</InputGroup.Text>
                                            <Field
                                                component="input"
                                                className="form-control"
                                                placeholder={ t('users.lastname') }
                                                name="lastname"
                                                defaultValue={ activeUser.lastname}
                                                autoFocus
                                                onKeyUp={ (e) => { setActiveUser({ ...activeUser, lastname: e.target.value}) }}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>{ t('users.email') }</InputGroup.Text>
                                            <Field
                                                component="input"
                                                className="form-control"
                                                placeholder={ t('users.email') }
                                                name="email"
                                                defaultValue={ activeUser.email}
                                                autoFocus
                                                onKeyUp={ (e) => { setActiveUser({ ...activeUser, email: e.target.value}) }}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>{ t('users.password') }</InputGroup.Text>
                                            <Field
                                                component="input"
                                                type="password"
                                                className="form-control"
                                                placeholder={ t('users.password') }
                                                name="password"
                                                autoFocus
                                                onKeyUp={ (e) => { setActiveUser({ ...activeUser, password: e.target.value}) }}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup className="form-row">
                                        <ButtonGroup>
                                            <Button  onClick={ () => { setActiveUser(null) }} variant="dark">
                                                <Icon path={ mdiArrowLeft } size={ 1 } color="white" />
                                                &nbsp;{ t('users.back') }
                                            </Button>
                                            <Button type="submit">
                                                { isLoading && (<Icon path={ mdiLoading } spin size={ 1 } />) }
                                                { !isLoading && (<Icon path={ mdiContentSave } size={ 1 } />) }
                                                &nbsp;{ t('users.save') }
                                            </Button>
                                        </ButtonGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </form>
                    )} />
                </div>
                )}

                { isLoading && (
                    <div className="text-center">
                        <Icon path={ mdiLoading } size={ 3 } spin={ 1 } />
                    </div>
                )}
            </div>
        </div>
    );
}

export default UsersList;
