import React from 'react';
import Project from './Project';
import CustomOpening from './CustomOpening';
import Path from './Path';
import paper from 'paper';

/**
 * Class representing a square opening
 */
class CustomOpeningWithBaseline extends CustomOpening {
    applyScale(scale: Number) {
        super.applyScale(scale);

        if (this.baseline.pathData) {
            const baseline = new Path(this.baseline.pathData);
            baseline.scale(scale);
            this.baseline.pathData = baseline.pathData;
        }
    }

    // inner shape = baseline
    getMarker1Path(offset) {
        const { xOffset, yOffset } = this.baseline;

        return new Path(this.baseline.pathData)
            .translate(new paper.Point(xOffset, yOffset - offset))
            .reorient(/*nonZero*/false, /*clockwise*/false);
    }

    getMarker2Path(offset) {
        if (this.scaleType === 'homothety') {
            return super.getMarker2Path(offset);
        }

        const project = Project.getInstance();
        const space   = (project.numerals.common.serifHeight);
        const mainPath = this.getMarker1Path(offset);
        const scaleXRatio = 1 + (space / mainPath.bounds.width);
        const scaleYRatio = 1 + (space / mainPath.bounds.height);

        return mainPath
            .scale(scaleXRatio, scaleYRatio)
            .reorient(/*nonZero*/false, /*clockwise*/true);
    }
}

export default CustomOpeningWithBaseline;
