import Point from './Point';
import paper from 'paper';

/**
 * Class representing an SVG path.
 */
class Path extends paper.Path {
    static circlePath (cx, cy, r) {
        return paper.Path.Circle(new Point(cx, cy), r);
    }

    static squarePath (x1, y1, x2, y2, r = null) {
        const rectangle = new paper.Rectangle(
            new Point(x2, y2),
            new Point(x1, y1),
        );
        return paper.Path.Rectangle(rectangle, r);
    }

    static squarePathWithSize(x1, y1, width, height, r = null) {
        const rectangle = new paper.Rectangle(
            new Point(x1, y1),
            new paper.Size(width, height)
        );
        return paper.Path.Rectangle(rectangle, r);
    }
}

export default Path;
