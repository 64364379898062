import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FullPageLoader } from '@cartier-studio/components';
import { updateViewOptions, loadProject } from '@cartier-studio/redux';
import { Opening, OpeningMarkers, Numeral, RadialLines } from '../../Entities';
import Draggable from 'react-draggable';
import './style.scss';

const Viewer = ({ project }) => {
    const { isLoadingOne } = useSelector(state => state.projects);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadProject(project._id));
    }, [dispatch, project._id, project.opening]);

    const onScroll = (e) => {
        dispatch(updateViewOptions({ zoom: Math.max(1, viewOptions.zoom - (e.deltaY / 100)) }));
    };

    const onDrag = (e: MouseEvent, data: Object) => {
        dispatch(updateViewOptions({ position: { x: data.x, y: data.y }}));
    };

    const { opening, viewOptions, layers } = project;

    if (!viewOptions)
        return null;

    const width  = opening?.width;
    const height = opening?.height;

    return (
        <Draggable position={ viewOptions.position } onStop={ onDrag } >
            <div className="Viewer" onWheel={onScroll}>
                { isLoadingOne && ( <FullPageLoader absolute /> )}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={ `0 0 ${width} ${height}` }
                    transform={ `scale(${ viewOptions.zoom }) rotate(${ viewOptions.rotation })` }
                    id="main-svg"
                >

                    { viewOptions.showOpening && (
                        <Opening project={ project } />
                    )}

                    <OpeningMarkers project={ project } />

                    { [...Array(12)].map((_,i) => layers[i + 1].visible && (
                        <Numeral key={ i } number={ i + 1 } project={ project } />
                    ))}

                    { viewOptions.showRadialLines && (
                        <RadialLines project={ project } />
                    )}
                </svg>
            </div>
        </Draggable>
    );
};

export default Viewer;
