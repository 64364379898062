import Project from './Project';
import Path from './Path';
import Point from './Point';
import Opening from './Opening';
import paper from 'paper';

/**
 * Class representing a numeral segment.
 * It represents a pair of symetrically opposed points around
 * the numeral axis.
 */
class Segment {
    constructor() {
        this.start = new Point(0, 0);
        this.end   = new Point(0, 0);
        this.path  = "";
    }

    /**
     * @param {Integer} positionIndex: on which position this segment is
     * @param {Integer} markerIndex: on which marker this segment is
     * @param {Number} startDistance: x distance between the first point and the radius
     * @param {Number} endDistance: x distance between the second point and the radius. Optional, defaults to -1 * startDistance
     */
    static betweenPoints(start: Point, end: Point): Segment {

        const segment = new Segment();
        segment.start = start;
        segment.end = end;

        segment.path = Path.Line(segment.start, segment.end);

        return segment;
    }

    /**
     * @param {Integer} positionIndex: on which position this segment is
     * @param {Integer} markerIndex: on which marker this segment is
     * @param {Number} startDistance: x distance between the first point and the radius
     * @param {Number} endDistance: x distance between the second point and the radius. Optional, defaults to -1 * startDistance
     * @param {Number} xOffset
     * @param {Number} yOffset
     * @param {Number} radialOffset
     */
    static onOpening(positionIndex: int, markerIndex: int, startDistance: Number, endDistance: Number, xOffset : Number = 0, yOffset: Number = 0, radialOffset: Number = 0, data: Object = {}): Segment {
        endDistance = endDistance || (-1 * startDistance);

        startDistance += xOffset;
        endDistance   += xOffset;

        let result = Segment.locatePoints(
            positionIndex, markerIndex,
            startDistance, endDistance,
            yOffset, radialOffset
        );
        const { start, end } = result;
        if ((start.x === 0 && start.y === 0) || (end.x === 0 && end.y === 0)) {
            // here we try to avoid the case where we can not locate the points,
            // i.e when we have a start or end point at (0, 0).
            // We do this by increasing the endDistance by 0.0001...
            result = Segment.locatePoints(
                positionIndex, markerIndex,
                startDistance, endDistance + 0.0001,
                yOffset, radialOffset
            );
        }
        const segment  = new Segment();
        segment.start  = result.start;
        segment.end    = result.end;
        segment.path   = result.path;
        segment.radius = result.radius;
        segment.data   = data;

        return segment;
    }

    static locatePoints(index = 1, markerIndex = 1, startDistance : Number = 0, endDistance : Number = 0, markerOffset: Number = 0, radialOffset: Number = 0) {
        const { opening, numeralArea } = Project.getInstance();
        const { xOffset, yOffset } = numeralArea?.center;

        startDistance = Math.round(startDistance * 100000) / 100000;
        endDistance   = Math.round(endDistance * 100000) / 100000;

        // build the line between the opening center and the number
        let radius = new paper.Path.Line(
            new paper.Point(
                opening.center.x - xOffset,
                opening.center.y - yOffset - 50
            ),
            new paper.Point(
                opening.center.x - xOffset,
                opening.center.y - yOffset
            )
        );
        // get the intersection between the opening marker and the radius line
        const markerPath = new paper.Path(
            opening.getMarkerPath(markerIndex, markerOffset)
        ).rotate(-1 * index * 30 + radialOffset, new paper.Point(opening.center.x, opening.center.y));

        const radiusOpeningIntersections = radius.getIntersections(markerPath);
        const radiusOpeningIntersection  = radiusOpeningIntersections && radiusOpeningIntersections[0] ? radiusOpeningIntersections[0].point : null;

        let intersections, start, end;
        // if the segment is not symetric around the radius, we have to compute the two
        // points separately
        if ((startDistance + endDistance) !== 0) {
            // we compute the (sorted) intersection points between the marker path and the circle
            // centered on radiusOpeningIntersection (with a radius equal to startDistance)
            intersections = (radiusOpeningIntersection ? markerPath.getIntersections(
                new paper.Path.Circle(
                    new Point(
                        radiusOpeningIntersection.x,
                        radiusOpeningIntersection.y
                    ),
                    Math.abs(startDistance)
                )
            ) : []).sort((a, b) => a.point.x - b.point.x);
            // The intersections should contain two points. We decide which one
            // to use depending on the startDistance value :
            // - if startDistance >= 0, we use the max(point.x)
            // - if startDistance < 0, we use the min(point.x)
            let intersectionPoint = intersections[startDistance > 0 ? 1 : 0];
            start = new Point(intersectionPoint?.point?.x || 0, intersectionPoint?.point?.y || 0);
            if (start.x === 0) {
                let intersectionPoint = intersections[startDistance > 0 ? 0 : 1];
                start = new Point(intersectionPoint?.point?.x || 0, intersectionPoint?.point?.y || 0);
            }

            // we compute the (sorted) intersection points between the marker path and the circle
            // centered on radiusOpeningIntersection (with a radius equal to endDistance)
            intersections = (radiusOpeningIntersection ? markerPath.getIntersections(
                new paper.Path.Circle(
                    new Point(
                        radiusOpeningIntersection.x,
                        radiusOpeningIntersection.y
                    ),
                    Math.abs(endDistance)
                )
            ) : []).sort((a, b) => a.point.x - b.point.x);
            // The intersections should contain two points. We decide which one
            // to use depending on the endDistance value :
            // - if endDistance >= 0, we use the max(point.x)
            // - if endDistance < 0, we use the min(point.x)
            intersectionPoint = intersections[endDistance > 0 ? 1 : 0];
            end = new Point(intersectionPoint?.point?.x || 0, intersectionPoint?.point?.y || 0);
            if (end.x === 0) {
                intersectionPoint = intersections[endDistance > 0 ? 0 : 1];
                end = new Point(intersectionPoint?.point?.x || 0, intersectionPoint?.point?.y || 0);
            }
        } else {
            // get the (sorted) intersection between the opening marker and a circle centered on the last intersection
            intersections = (radiusOpeningIntersection ? markerPath.getIntersections(
                new paper.Path.Circle(
                    new Point(
                        radiusOpeningIntersection.x,
                        radiusOpeningIntersection.y
                    ),
                    Math.abs(startDistance)
                )
            ) : []).sort((a, b) => a.point.x - b.point.x);

            // The intersections should contain two points. We decide which one
            // to use depending on the startDistance and the endDistance values :
            // - if startDistance <= endDistance, start will be the smallest point (smallest x value)
            // - if startDistance > endDistance, start will be the biggest point (biggest x value)
            // - if startDistance <= endDistance, end will be the biggest point (biggest x value)
            // - if startDistance > endDistance, end will be the smallest point (smallest x value)
            const startIntersectionPoint = intersections[startDistance <= endDistance ? 0 : 1];
            start = new Point(startIntersectionPoint?.point?.x || 0, startIntersectionPoint?.point?.y || 0);

            const endIntersectionPoint = intersections[startDistance <= endDistance ? 1 : 0];
            end = new Point(endIntersectionPoint?.point?.x || 0, endIntersectionPoint?.point?.y || 0);
        }

        const path = Opening.followOpeningSegment(
            start,
            end,
            markerPath
        );

        return { radius, path, start, end };
    }

    lineTo(other: Segment): string {
        if (!this.end || !other.start)
            return null;

        if (this.end.x === 0 || other.start.x === 0)
            return null;

        return new paper.Path.Line(
            new Point(this.end.x, this.end.y),
            new Point(other.start.x, other.start.y)
        ).pathData;
    }

    getIntersectionWith(other: Segment): Point {
        const intersections =  this.path.getIntersections(other.path);

        if (!intersections || intersections.length < 1)
            return null;

        return intersections[0].point;
    }

    getPoint(distance: Number) {
        const startingPoint = distance > 0 ? this.end : this.start;
        const intersections = this.path.getIntersections(new paper.Path.Circle(startingPoint, Math.abs(distance)));
        if (intersections?.length > 0)
            return intersections[0].point;

        const vector = new Point(this.end?.x - this.start?.x, this.end?.y - this.start?.y);
        const result = new Point(this.start?.x + (vector?.x * distance / 100), this.start?.y + (vector?.y * distance / 100));
        return result;
    }
}

export default Segment;
