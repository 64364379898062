import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    RomanOne, RomanTwo, RomanThree, RomanFour,
    RomanFive, RomanSix, RomanSeven, RomanEight,
    RomanNine, RomanTen, RomanEleven, RomanTwelve
} from '@cartier-studio/romans-lib';
import { updateLayers, updateViewOptions } from '@cartier-studio/redux';
import './style.scss';

const bindings = {
    1: RomanOne,
    2: RomanTwo,
    3: RomanThree,
    4: RomanFour,
    5: RomanFive,
    6: RomanSix,
    7: RomanSeven,
    8: RomanEight,
    9: RomanNine,
    10: RomanTen,
    11: RomanEleven,
    12: RomanTwelve
}

const Numeral = ({ number, project }) => {
    const [ model, setModel ]             = useState(null);
    const [ path, setPath ]               = useState(null);
    const [ boundingBox, setBoundingBox ] = useState(null);

    const { isExporting, viewer }    = useSelector(state => state.editor);
    const { zoom, position } = project.viewOptions;

    const dispatch = useDispatch();

    useEffect(() => {
        setModel(new (bindings[number])());
    }, [number]);

    const { numeralArea, layers, numerals: { common }, signature } = project;
    const numerals = project?.numerals[number];
    useEffect(() => {
        if (model) {
            model.buildSegments();
            setPath(model.toJsx());
            setBoundingBox(model.getJsxBoundingBox());
        }
    }, [model, numeralArea, common, numerals, signature, project.opening]);

    if (!path || !project)
        return null;

    const onClick = e => {
        if (e.detail === 2) {
            const newZoom = 50;
            const newPosition = {
                x:  (newZoom / zoom) * (position.x - (e.screenX - (viewer.width / 2))),
                y:  (newZoom / zoom) * (position.y - (e.screenY - (viewer.height / 2))),
            };
            dispatch(updateViewOptions({ zoom: newZoom, position: newPosition }));
        }

        if (layers.active !== number)
            setTimeout(() => {
                dispatch(updateLayers({ ...layers, active: number }));
            }, 100);

        dispatch(updateLayers({ ...layers, active: null }));
    };

    return (
        <g
            id={`roman${number}-wrapper`}
            className={ `Numeral ${layers.active === number ? 'active' : ''}` }
            onClick={ onClick }
            fill="none"
            stroke="#000000"
            strokeWidth="0.01"
        >
            { path }
            { !isExporting && boundingBox }
        </g>
    );
};

export default Numeral;
