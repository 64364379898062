import { useDispatch } from 'react-redux';
import { updateViewOptions } from '@cartier-studio/redux';
import { Slider } from '../Slider';
import { Button } from 'react-bootstrap';
import Icon from '@mdi/react';
import {
    mdiFitToScreen,
    mdiMagnifyMinusOutline,
    mdiMagnifyPlusOutline,
} from '@mdi/js';
import './style.scss';

const ZoomSlider = ({ project }) => {
    const dispatch = useDispatch();
    const onChange = (e) => {
        const zoom = e.target.value;
        dispatch(updateViewOptions({ zoom }));
    }

    const fitToScreen = (e) => {
        const zoom = 5;
        const position = { x: 0, y: 0 };
        dispatch(updateViewOptions({ zoom, position }));
    }

    if (!project || !project.viewOptions)
        return null;

    const { zoom } = project.viewOptions;

    return (
        <div className="ZoomSlider">
            <Button onClick={() => fitToScreen() }>
                <Icon color='#FFFFFF' variant="primary" size={ 1 } path={ mdiFitToScreen } />
            </Button>
            <Icon path={ mdiMagnifyMinusOutline } />
            <Slider
                min="1"
                max="100"
                step="0.5"
                value={ zoom }
                onChange={ onChange }
            />
            <Icon path={ mdiMagnifyPlusOutline } />
        </div>
    );
};

export default ZoomSlider;
