import Roman from './Roman';
import Project from './Project';
import Segment from './Segment';
import Logo from './Logo';

/**
 * Class representing a roman 10 number
 *
 *           U________V     b__________A
 *           |        |     |          |
 *           T__S  X__W     a__Z C_____B
 *               \  \          //
 *                \  \        ef
 *                 \  \
 *                  \  \    gh
 *                   \  \  //
 *                    \   Y/
 *                      R  D
 *                      /  \
 *                     / K  \
 *                    ij  \  \
 *                   kl    \  \
 *                  //      \  \
 *            P____QL__M  I__J  \E___F
 *            |        |  |          |
 *            O________N  H__________G
 */
class RomanTen extends Roman {
    constructor() {
        super(/*index*/10);
    }

    buildSegments() {
        const project = Project.getInstance();
        if (!project.numerals)
            return;

        const { signature } = project;
        const { common }    = project.numerals;
        const numerals      = project.numerals[this.positionIndex];

        const sfW  = common.serifWidth;
        const bW   = common.barsWidth;
        const tbW  = common.thinBarsWidth;
        const xftw = numerals.xFullTopWidth;
        const xfbw = numerals.xFullBottomWidth;
        const radialOffset = project.numerals[this.positionIndex].followOffsetAxis ? 0 : project.numerals[this.positionIndex].offset / 2.6;

        // shortcut
        const s = (marker, first, last, data) => Segment.onOpening(10, marker, first, last, /*xOffset*/0, /*yOffset*/0, /*radialOffset*/0, data);

        const A = xftw / 2 + sfW + radialOffset;
        const U = -1 * A + (2 * radialOffset);
        const S = U + sfW;
        const C = S + xftw;

        const Z = C - tbW;
        const B = C + sfW;
        const b = A - tbW - (2 * sfW);
        const a = b;

        const T = U;
        const X = S + bW;
        const W = X + sfW;
        const V = W;

        const P = T;
        const Q = P + sfW;
        const L = Q + tbW;
        const M = L + sfW;
        const N = M;
        const O = P;

        const E = Q + xfbw;
        const F = E + sfW;
        const G = F;
        const I = F - 2 * sfW - bW;
        const H = I;
        const J = I + sfW;

        const bA = s(4, b, A, {startLabel: 'b', endLabel: 'A'});
        const BC = s(3, B, C, {startLabel: 'B', endLabel: 'C'});
        const EF = s(2, E, F, {startLabel: 'E', endLabel: 'F'});
        const GH = s(1, G, H, {startLabel: 'G', endLabel: 'H'});
        const IJ = s(2, I, J, {startLabel: 'I', endLabel: 'J'});
        const LM = s(2, L, M, {startLabel: 'L', endLabel: 'M'});
        const NO = s(1, N, O, {startLabel: 'N', endLabel: 'O'});
        const PQ = s(2, P, Q, {startLabel: 'P', endLabel: 'Q'});
        const ST = s(3, S, T, {startLabel: 'S', endLabel: 'T'});
        const UV = s(4, U, V, {startLabel: 'U', endLabel: 'V'});
        const WX = s(3, W, X, {startLabel: 'W', endLabel: 'X'});
        const Za = s(3, Z, a, {startLabel: 'Z', endLabel: 'a'});

        const XE = Segment.betweenPoints(WX.end, EF.start);
        const SJ = Segment.betweenPoints(ST.start, IJ.end);
        const ZQ = Segment.betweenPoints(Za.start, PQ.end);
        const CL = Segment.betweenPoints(BC.end, LM.start);

        const Ypoint = XE.getIntersectionWith(ZQ);
        const Kpoint = SJ.getIntersectionWith(CL);
        const Dpoint = CL.getIntersectionWith(XE);
        const Rpoint = ZQ.getIntersectionWith(SJ);

        const Y  = Segment.betweenPoints(Ypoint, Ypoint);
        const K  = Segment.betweenPoints(Kpoint, Kpoint);
        const D  = Segment.betweenPoints(Dpoint, Dpoint);
        const R  = Segment.betweenPoints(Rpoint, Rpoint);

        this.boundingBoxCorners = [ UV.start, bA.end, GH.start, NO.end ];

        // no signature
        if (signature.onNumeral !== 10)
            return this.segments = [
                [
                    bA,
                    BC,
                    D,
                    EF,
                    GH,
                    IJ,
                    K,
                    LM,
                    NO,
                    PQ,
                    R,
                    ST,
                    UV,
                    WX,
                    Y,
                    Za
                ]
            ];

        // signature
        const options = signature[signature.onNumeral];
        // car
        const ZY = Segment.betweenPoints(Za.start, Y.start);
        const CD = Segment.betweenPoints(BC.end, D.start);
        const e = ZY.getPoint(-1 * options.thinBarLength1);
        const f = CD.getPoint(-1 * options.thinBarLength2);
        const h = CD.getPoint(options.thinBarLength4);
        const g = ZY.getPoint(options.thinBarLength3);
        const fe = Segment.betweenPoints(f, e);
        const gh = Segment.betweenPoints(g, h);

        // tier
        const QR = Segment.betweenPoints(PQ.end, R.start);
        const LK = Segment.betweenPoints(LM.start, K.start);
        const k = QR.getPoint(-1 * options.thinBarLength5);
        const l = LK.getPoint(-1 * options.thinBarLength6);
        const i = LK.getPoint(options.thinBarLength7);
        const j = QR.getPoint(options.thinBarLength8);
        const kl = Segment.betweenPoints(k, l);
        const ij = Segment.betweenPoints(i, j);
        this.segments = [
            [
                bA,
                BC,
                fe,
                Za
            ],
            [
                gh,
                D,
                EF,
                GH,
                IJ,
                K,
                ij,
                R,
                ST,
                UV,
                WX,
                Y,
            ],
            [
                kl,
                LM,
                NO,
                PQ,
            ],
        ];

    }

    getJsxExtraPaths() {
        const project = Project.getInstance();
        if (!project.signature)
            return null;

        return project.signature.onNumeral === 10 ? (new Logo()).toJsx() : null;
    }

    getSvgExtraPaths() {
        const project = Project.getInstance();
        if (!project.signature)
            return null;

        return project.signature.onNumeral === 10 ? (new Logo()).toSvg() : null;
    }
}

export default RomanTen;
