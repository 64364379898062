import { useDispatch } from 'react-redux';
import { ButtonGroup, Button } from 'react-bootstrap';
import { updateViewOptions, updateUser } from '@cartier-studio/redux';
import Icon from '@mdi/react';
import { mdiThemeLightDark } from '@mdi/js';
import './style.scss';

const ViewerOptions = ({ project }) => {
    const dispatch = useDispatch();

    if (!project)
        return null;

    const switchOption = (option) => {
        dispatch(updateViewOptions({
            [option]: !viewOptions[option]
        }));
    }

    const setTheme = theme => {
        dispatch(updateViewOptions({ theme }));
    };


    const { viewOptions } = project;

    return (
        <div className="ViewerOptions">
            { viewOptions && (
            <div className="options">
                <Button
                    className="option"
                    variant={ viewOptions.showBackground ? 'primary' : 'secondary' }
                    onClick={() => switchOption('showBackground')}
                >
                    <i className={ `fas fa-${  viewOptions.showBackground ? 'eye' : 'eye-slash' }` } />{' '}
                    Image de fond
                </Button>
                <Button
                    className="option"
                    variant={ viewOptions.showOpening ? 'primary' : 'secondary' }
                    onClick={() => switchOption('showOpening')}
                >
                    <i className={ `fas fa-${  viewOptions.showOpening ? 'eye' : 'eye-slash' }` } />{' '}
                    Ouverture
                </Button>
                <Button
                    className="option"
                    variant={ viewOptions.showOpeningMarkers ? 'primary' : 'secondary' }
                    onClick={() => switchOption('showOpeningMarkers')}
                >
                    <i className={ `fas fa-${  viewOptions.showOpeningMarkers ? 'eye' : 'eye-slash' }` } />{' '}
                    Repères
                </Button>
                <Button
                    className="option"
                    variant={ viewOptions.showRadialLines ? 'primary' : 'secondary' }
                    onClick={() => switchOption('showRadialLines')}
                >
                    <i className={ `fas fa-${  viewOptions.showRadialLines ? 'eye' : 'eye-slash' }` } />{' '}
                    Rayons
                </Button>
                <div className="options-separator"></div>
                { viewOptions.theme && (
                <ButtonGroup className="option-group">
                    <Button
                        className="option"
                        onClick={() => setTheme('light')}
                        variant={ viewOptions.theme === 'light' ? 'primary' : 'secondary' }
                    >
                        <Icon color="#FFFFFF" className="theme-icon" path={ mdiThemeLightDark } />
                    </Button>
                    <Button
                        className="option"
                        onClick={() => setTheme('dark')}
                        variant={ viewOptions.theme === 'dark'? 'primary' : 'secondary' }
                    >
                        <Icon color="#000000" className="theme-icon" path={ mdiThemeLightDark } />
                    </Button>
                </ButtonGroup>
                )}
            </div>
            )}
        </div>
    );
};

export default ViewerOptions;
