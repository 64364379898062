import Client from '../Client';
import _ from 'lodash';

class RestClient extends Client {
    constructor(config) {
        super();

        this.LIST_PATH   = '';
        this.GET_PATH    = '';
        this.UPDATE_PATH = '';
        this.CREATE_PATH = '';
        this.DELETE_PATH = '';

        this.entryPoint = config.entryPoint;
        this.resource   = config.resource;
        this.resources  = config.resources;
        this.model      = config.model;
        this.id_field   = config.id_field || 'id';
        this.sortBy     = config.sortBy;
    }

    list(params) {
        // remove empty filter values
        params = _.pickBy(params, _.identity);

        // stringify fields
        const fields = (this.model.getListingFields() || []).join(',');

        return this.GET(`/${this.entryPoint}${this.LIST_PATH}`, { ...params, fields })
            .then(response => response.json())
            .then(json => {
                if (json[this.resources]) {
                    json[this.resources] = json[this.resources].map(r => new this.model(r));

                    if (this.sortBy)
                        json[this.resources] = _.sortBy(json[this.resources], this.sortBy);

                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    get(id) {
        return this.GET(`/${this.entryPoint}${this.GET_PATH}/${id}`)
            .then(response => response.json())
            .then(json => {
                if (json[this.resource]) {
                    json[this.resource] = new this.model(json[this.resource]);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    create(payload) {
        payload = new this.model(payload);

        if ('prepareForUpdate' in payload)
            payload = payload.prepareForUpdate();

        return this.POST(`/${this.entryPoint}${this.CREATE_PATH}`, { [this.resource]: payload })
            .then(response => response.json())
            .then(json => {
                if (json[this.resource]) {
                    json[this.resource] = new this.model(json[this.resource]);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    update(payload) {
        payload = new this.model(payload);
        if ('prepareForUpdate' in payload)
            payload = payload.prepareForUpdate();

        return this.PUT(
                `/${this.entryPoint}${this.UPDATE_PATH}/${payload[this.id_field]}`,
                { [this.resource]: payload }
            ).then(response => response.json())
            .then(json => {
                if (json[this.resource]) {
                    json[this.resource] = new this.model(json[this.resource]);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    duplicate(payload) {
        payload = new this.model(payload);
        if ('prepareForDuplicate' in payload)
            payload = payload.prepareForDuplicate();
        if ('prepareForUpdate' in payload)
            payload = payload.prepareForUpdate();

        return this.POST(`/${this.entryPoint}${this.CREATE_PATH}`, { [this.resource]: payload })
            .then(response => response.json())
            .then(json => {
                if (json[this.resource]) {
                    json[this.resource] = new this.model(json[this.resource]);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    delete(id) {
        return this.DELETE(`/${this.entryPoint}${this.DELETE_PATH}/${id}`, {})
            .then(response => response.json())
            .then(json => {
                if (json.flash && json.flash.error)
                    throw new Error(json.flash.error);

                return json;
            });
    }
}

export default RestClient;
