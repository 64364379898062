import { useDispatch, useSelector } from 'react-redux';
import { logo } from '@cartier-studio/assets';
import { logout } from '@cartier-studio/redux';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Icon from '@mdi/react';
import {
    mdiFilePlusOutline,
    mdiFolderOpenOutline,
    mdiExport,
    mdiShareVariantOutline,
    mdiAccountGroupOutline,
    mdiLogout
} from '@mdi/js';
import './style.scss';

const Header = () => {
    const { user }    = useSelector(state => state.auth);
    const { project } = useSelector(state => state.editor);

    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(logout());
    }

    return (
        <header className="Header">
            <Navbar expand="md" bg="dark" fixed="top" className="fixed-top">
                <Navbar.Brand href="#">
                    <img className="img" src={ logo } alt="Cartier Studio" />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="navbar-nav"/>

                <Navbar.Collapse id="navbar-nav">
                    <Nav className="ms-auto pe-md-1 user-menu" navbar>
                        <NavDropdown title={ user.firstname }>
                            <NavDropdown.Item onClick={ handleLogout }>
                                <Icon path={ mdiLogout } /> Se déconnecter
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
};

export default Header;
