import { useEffect } from 'react';
import { Project } from '@cartier-studio/romans-lib';
import './style.scss';

const Opening = () => {
    const project = Project.getInstance();

    useEffect(() => {
        // do nada, just force refresh
    }, [project]);

    if (!project.opening)
        return null;

    return project.opening.toJsx();
};

export default Opening;
