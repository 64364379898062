import Opening from './Opening';
import CircleOpening from './CircleOpening';
import SquareOpening from './SquareOpening';
import CustomOpening from './CustomOpening';
import CustomOpeningWithBaseline from './CustomOpeningWithBaseline';
import NumeralArea from './NumeralArea';
import RomanOne from './RomanOne';
import RomanTwo from './RomanTwo';
import RomanThree from './RomanThree';
import RomanFour from './RomanFour';
import RomanFive from './RomanFive';
import RomanSix from './RomanSix';
import RomanSeven from './RomanSeven';
import RomanEight from './RomanEight';
import RomanNine from './RomanNine';
import RomanTen from './RomanTen';
import RomanEleven from './RomanEleven';
import RomanTwelve from './RomanTwelve';
import moment from 'moment';
import paper from 'paper';

const bindings = {
    1: RomanOne,
    2: RomanTwo,
    3: RomanThree,
    4: RomanFour,
    5: RomanFive,
    6: RomanSix,
    7: RomanSeven,
    8: RomanEight,
    9: RomanNine,
    10: RomanTen,
    11: RomanEleven,
    12: RomanTwelve
}
/**
 * Class representing a project
 */
class Project {
    static instance;
    static __initialized: false;

    static isInitialized(): boolean {
        return !!Project.__initialized;
    }

    static setup(options: Object): Project {
        Project.instance = new Project(options);
        Project.__initialized = true;
        return Project.instance;
    }

    static getInstance(): Project {
        if (!Project.instance)
            throw new Error('Uninitialized project: please call setup() first.');

        return Project.instance;
    }

    constructor(properties: Object) {
        Object.assign(this, properties || {});

        if (this.opening) {
            switch (this.opening.shape) {
                case 'square':
                    this.setOpening(new SquareOpening(properties.opening));
                    break;
                case 'custom':
                    this.setOpening(properties.opening.baseline?.pathData ?
                        new CustomOpeningWithBaseline(properties.opening) :
                        new CustomOpening(properties.opening)
                    );
                    break;
                case 'circle':
                default:
                    this.setOpening(new CircleOpening(properties.opening));
            }
        }

        if (this.numeralArea)
            this.setNumeralArea(new NumeralArea(properties.numeralArea));

        if (this.createdAt)
            this.createdAt = moment(this.createdAt);

        if (this.updatedAt)
            this.updatedAt = moment(this.updatedAt);
    }

    setOpening(opening: Opening): Project {
        this.opening = opening;
        paper.setup(new paper.Size(1, 1)); // let's create a virtual canvas
        paper.view.autoUpdate = false; // let's disable drawing any shape automatically

        return this;
    }

    setNumeralArea(numeralArea): Project {
        this.numeralArea = numeralArea;
        return this;
    }

    toSvg(): String {
        const width  = this.opening?.width;
        const height = this.opening?.height;
        const radius = 31.75;
        const center = {
            x: width / 2,
            y: height / 2,
            radius: 0.99
        };

        const svg = [];
        svg.push(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}" transform="scale(${this.viewOptions.zoom}) rotate(${this.viewOptions.rotation})" id="main-svg" >`);

        svg.push('<style type="text/css">');
        svg.push('.numeral-path, .logo-path, .opening-path, .opening-center { fill: none; stroke: #000000; stroke-width: .1; stroke-miterlimit: 10; stroke-linecap: round; } .logo-path { stroke-width: 0.05; } .radial-line { stroke: #CCCCCC; stroke-width: 0.1; } .OpeningMarkers .numeral-area { fill: none; stroke: #CCCCCC; stroke-width: 0.05; stroke-miterlimit: 10; stroke-linejoin: round; } .OpeningMarkers .numeral-area.c1 { fill: #FFFFFF; } .OpeningMarkers .numeral-area.c2 { fill: #7594fe05; } .OpeningMarkers .numeral-area.c3 { fill: #7594fe05; } .OpeningMarkers .numeral-area.c4 { fill: #7594fe05; }');
        svg.push('</style>');

        if (this.viewOptions.showOpening)
            svg.push(this.opening.toSvg());

        [...Array(12)].forEach((_,i) => {
            if (this.layers[i + 1].visible) {
                const roman = new (bindings[i + 1])();
                svg.push(roman.toSvg());
            }
        });

        svg.push('</svg>');

        return svg.join('');
    }
}

export default Project;
