import './style.scss';

const Slider = ({ min, max, step, value, onChange}) => {
    return (
        <input
            type="range"
            className="Slider form-range"
            min={ min }
            max={ max }
            step={ step }
            value={ value }
            onChange={ onChange }
        />
    );
};

export default Slider;
