import BaseModel from './BaseModel';
import _ from 'lodash';
import { v4 } from 'uuid';

/**
 * Class representing a directory
 */
class Directory extends BaseModel {
    /**
     * Get the fields to get server side when we
     * ask for a listing
     */
    static getListingFields() {
        return [];
    }

    isRoot() {
        return this.parent === null;
    }

    isLeaf() {
        return !this.isRoot();
    }
}

export default Directory;
