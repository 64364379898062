import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { ProjectInfos } from './ProjectInfos';
import { ProjectOptions } from './ProjectOptions';
import { Viewer } from './Viewer';
import { updateViewer } from '@cartier-studio/redux';

import './style.scss';

const Project = ({ project, showProjectDetails, showExplorer, showExporter }) => {
    const wrapperRef = useRef(null);

    const dispatch = useDispatch();
    useEffect(() => {
        const handleWindowResize = () => {
            if (wrapperRef.current)
                dispatch(updateViewer({
                    width: wrapperRef.current.offsetWidth,
                    height: wrapperRef.current.offsetHeight
                }));
        }

        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    if (!project)
        return null;

    return (
        <div className={ `Project ${project?.viewOptions?.theme}` }>
            <Row className="project-infos-row">
                <Col>
                    <ProjectInfos
                        project={ project }
                        showProjectDetails={ showProjectDetails }
                        showExplorer={ showExplorer }
                        showExporter={ showExporter }
                    />
                </Col>
            </Row>
            <Row className="project-editor-row">
                <Col ref={ wrapperRef} className="viewer-wrapper" md={ 8 }>
                    <Viewer project={ project } />
                </Col>
                <Col className="options-wrapper" md={ 4 }>
                    <ProjectOptions project={ project } />
                </Col>
            </Row>
        </div>
    );
};

export default Project;
