import { Config } from '@cartier-studio/config';
import { store } from '@cartier-studio/redux';

export default class Client {
    constructor() {
        this.baseUrl = Config.api_end_point;
        this.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
    }

    _fetch(path, method, query, body = null, extraHeaders = {}) {
        const user = store.getState().auth.user;
        if (user && user.token) {
            query = query || {};
            query.token = user.token;
        }

        let url = `${this.baseUrl}${path}`;
        if (query) {
            let qs = require('qs');
            let q  = qs.stringify(query);
            url = `${url}?${q}`;
        }

        let options = {
            method,
            headers: { ...this.headers, ...extraHeaders }
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        return fetch(url, options);
    }

    GET(route, query, extraHeaders = {}) {
        return this._fetch(route, 'GET', query, null, extraHeaders);
    }

    POST(route, body, extraHeaders = {}) {
        return this._fetch(route, 'POST', null, body, extraHeaders);
    }

    PUT(route, body, extraHeaders = {}) {
        return this._fetch(route, 'PUT', null, body, extraHeaders);
    }

    DELETE(route, query, extraHeaders = {}) {
        return this._fetch(route, 'DELETE', query, null, extraHeaders);
    }
};
