import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';

import './Confirm.scss';

const Confirm = ({ visible, onHide, onConfirm, title, question, text }) => {
    const { t } = useTranslation();

    return (
        <Modal className="Confirm" show={ visible } size="md" centered >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    { title }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { question && (
                    <h4>{ question }</h4>
                )}
                { text && (
                    <p>{ text }</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={ onHide } variant="dark">{ t('common.no') }</Button>
                <Button onClick={ onConfirm }>{ t('common.yes') }</Button>
            </Modal.Footer>
    </Modal>
    );
}

export default Confirm;
