import React from 'react';
import Project from './Project';
import Opening from './Opening';
import Path from './Path';
import paper from 'paper';

/**
 * Class representing a square opening
 */
class CustomOpening extends Opening {
    constructor(properties: Object) {
        super(properties);

        paper.setup(new paper.Size(1, 1)); // let's create a virtual canvas
        paper.view.autoUpdate = false; // let's disable drawing any shape automatically

        if (this.pathData) {
            this.path = new Path(this.pathData);
        }
    }

    applyScale(scale: Number) {
        this.path.scale(scale);
        this.pathData = this.path.pathData;
    }

    getPath() {
        return this.path;
    }

    getPathData() {
        const { pathData } = this.getPath();
        return  pathData;
    }

    getMarker1Path(offset) {
        const project = Project.getInstance();
        const space   = (project.numeralArea.margin + project.numeralArea.height + offset);
        const mainPath = new Path(this.getPathData());
        const scaleXRatio = 1 - (space / mainPath.bounds.width);
        const scaleYRatio = this.scaleType === 'homothety' ? scaleXRatio : 1 - (space / mainPath.bounds.height);
        return mainPath
            .scale(scaleXRatio, scaleYRatio)
            .reorient(/*nonZero*/false, /*clockwise*/false);
    }

    getMarker2Path(offset) {
        const project = Project.getInstance();
        const space   = (project.numeralArea.margin + project.numeralArea.height - project.numerals.common.serifHeight + offset);
        const mainPath = new Path(this.getPathData());
        const scaleXRatio = 1 - (space / mainPath.bounds.width);
        const scaleYRatio = 1 - (space / mainPath.bounds.height);
        return mainPath
            .scale(scaleXRatio, scaleYRatio)
            .reorient(/*nonZero*/false, /*clockwise*/true);
    }

    getMarker3Path(offset) {
        const project = Project.getInstance();
        const space   = (project.numeralArea.margin + project.numerals.common.serifHeight + offset);
        const mainPath = new Path(this.getPathData());
        const scaleXRatio = 1 - (space / mainPath.bounds.width);
        const scaleYRatio = 1 - (space / mainPath.bounds.height);
        return mainPath
            .scale(scaleXRatio, scaleYRatio)
            .reorient(/*nonZero*/false, /*clockwise*/false);
    }

    getMarker4Path(offset) {
        const project = Project.getInstance();
        const space   = (project.numeralArea.margin + offset);
        const mainPath = new Path(this.getPathData());
        const scaleXRatio = 1 - (space / mainPath.bounds.width);
        const scaleYRatio = 1 - (space / mainPath.bounds.height);
        return mainPath
            .scale(scaleXRatio, scaleYRatio)
            .reorient(/*nonZero*/false, /*clockwise*/true);
    }
}

export default CustomOpening;
