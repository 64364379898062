import { createTransform } from 'redux-persist';
import { Project } from '@cartier-studio/romans-lib';

const PersistTransformer = createTransform(
    // on serialize
    (inboundState, key) => {
        return { ...inboundState };
    },

    // on unserialize
    (outboundState, key) => {
        switch(key) {
            case 'editor':
                return {
                    ...outboundState,
                    project: new Project(outboundState.project)
                }
            default:
                return outboundState;
        }
    }
);

export default PersistTransformer;
